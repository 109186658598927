import React, { useEffect, useMemo } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { routes } from './RoutePaths';
import TmLayout from '../Pages/Layout/TmLayout';
import { useAuth } from '../Context/AuthContext';
import Login from '../Pages/Login/Login';
import Page404 from '../Pages/NotFound/Page404';
import ResetPasswordPage from '../Pages/Login/ResetPasswordPage';
import { useSelector } from 'react-redux';
import { getDashboard } from '../store/reducers/actionPermission';
import TimeSheetPage from '../Pages/TimeSheet/TimeSheetPage';
import Dashboard from '../Pages/Dashboard/Dashboard';
import { handleSessionTimings } from '../ReusableFn/reusableFn';
import { WITHOUT_CONFIG } from '../constant/constant';

function AppRouter() {
  const { username } = useAuth();

  const data = useSelector(getDashboard);

  const appRoutes = useMemo(() => {
    const modifiedRoutes = [...routes];
    if (data?.checked) {
      modifiedRoutes.push({ path: '/', component: <Dashboard /> });
    } else {
      modifiedRoutes.push({ path: '/', component: <TimeSheetPage /> });
    }
    return modifiedRoutes;
  }, [data, routes]);

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (!document.hidden) {
        if (username) {
          await handleSessionTimings(null, WITHOUT_CONFIG);
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <BrowserRouter>
      {!username ? (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/reset_password/:emailId"
            element={<ResetPasswordPage />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      ) : (
        <TmLayout>
          <Routes>
            {appRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.component} />
            ))}
            <Route path="*" element={<Page404 />} />
          </Routes>
        </TmLayout>
      )}
    </BrowserRouter>
  );
}

export default AppRouter;
