import { useEffect, useState, useRef } from 'react';
import { Row, Col } from 'reactstrap';
import style from '../../Styles/UserManagement.css';
import UserManagementTable from '../UserManagement/UserManagementTable';
import { useNavigate } from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import { Button, Input } from 'antd';
import { toastError, toastSuccess, toastWarn } from '../Toast/ToastComponent';
import {
  EMAIL_NOTIFICATIONS,
  MODAL_HEIGHT,
  STATUS,
  STATUSMAIL,
} from '../../constant/constant';
import TmFilter from '../ReusableComponents/TmFilter';
import TmMasterTable from '../ReusableComponents/TmMasterTable';
import ModalComponent from '../ReusableComponents/ModalComponent';
import AddEmail from './AddEmail';
import EmailApi from '../../Api/EmailApi';
import { DeletePopup } from '../ReusableComponents/DeletePopup';
import { emailComponentHeader } from '../../constant/tableHeader';
import { getEmail } from '../../store/reducers/actionPermission';
import { useSelector } from 'react-redux';
import AntdModalComp from '../ReusableComponents/AntdModalComp';
import Department from '../../Api/Department';

const { Search } = Input;

let modalHeader;
let modalData = {};

function EmailComponent() {
  const [users, setUsers] = useState([]);
  const [filterValue, setFilterValue] = useState({
    status: '',
    type: '',
    departmentsID: '',
  });
  const [modal, setModal] = useState(false);

  const navigate = useNavigate();
  const debounceTimeoutRef = useRef(null);
  const data = useSelector(getEmail);

  const [buttonPermission, setButtonPermissions] = useState({
    add: undefined,
    edit: undefined,
    delete: undefined,
  });

  const [departmentList, setDepartmentList] = useState([]);

  const filterArray = [
    {
      options: EMAIL_NOTIFICATIONS,
      optionFilterProp: 'label',
      placeholder: 'Title',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: null,
      controlName: 'type',
      allowClear: true,
      input: 'select',
    },
    {
      options: departmentList,
      optionFilterProp: 'label',
      placeholder: 'Department',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: null,
      controlName: 'departmentsID',
      allowClear: true,
      input: 'select',
    },
    {
      options: STATUSMAIL,
      optionFilterProp: 'label',
      placeholder: 'Status',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: null,
      controlName: 'status',
      allowClear: true,
      input: 'select',
    },
    {
      order: 1,
      col: 'auto',
      className: 'ms-auto pe-0',
      input: 'button',
      type: 'dashed',
      htmlType: 'button',
      text: 'Reset',
    },
  ];

  const handleButtonPermission = () => {
    if (!data?.checked) {
      navigate('/');
    }
    for (const element of data?.actions) {
      const keyName = element?.statename?.toLowerCase();
      setButtonPermissions((prevState) => ({
        ...prevState,
        [keyName]: element?.checked,
      }));
    }
  };

  const handleFilterChange = (obj, type) => {
    // filterValue = obj || {};
    getEmailList({ ...filterValue, ...obj });
    setFilterValue((pre) => ({ ...pre, ...obj }));
  };

  const toggleModal = (type, value) => {
    if (type == 'Close') {
      setModal(!modal);
      return;
    }

    if (value && (type === 'View' || type === 'Edit')) {
      modalData = {
        title: value.title,
        id: value.id,
        type: value.type,
        content: value.content,
        status: value.status,
        bccID: value.bccID,
        ccID: value.ccID,
        departmentsID: value.departmentsID,
        subject: value.subject,
      };
    } else if (type === 'Add') {
      modalData = {};
    }
    modalHeader = <span className="text-nowrap">{`${type} Email`}</span>;
    setModal(!modal);
  };

  const getEmailList = async (filterObj) => {
    try {
      let response = await EmailApi.getEmailList({ ...filterObj });
      if (response?.data?.success) {
        let res = [];
        res = response.data.result;
        setUsers(res);
      } else {
        toastError(response?.data?.message);
        return;
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  const handleSearch = (e) => {
    let value = e.target.value.trim();

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      handleFilterChange({ ...filterValue, search: value });
    }, 400);
    setFilterValue((pre) => ({ ...filterValue, search: value }));
  };

  const callDelete = async (id) => {
    await DeletePopup(async (canDelete) => {
      if (canDelete) {
        try {
          const response = await EmailApi.delete(id);
          if (response.data.success) {
            toastSuccess(response.data.message);
            getEmailList(filterValue);
          } else {
            toastWarn(response.data.message);
          }
        } catch (error) {
          //toastError(error.response.data.message);
          toastError(error);
        }
      }
    });
  };

  const getDropDown = async () => {
    try {
      const response = await Department.dropDown();
      if (response?.data?.success) {
        setDepartmentList(response?.data?.result);
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  useEffect(() => {
    getDropDown();
    getEmailList(filterValue);
    if (data) {
      handleButtonPermission();
    }
  }, [data]);

  return (
    <>
      <div className="row mx-0">
        <div className="col-md-4 px-0">
          <h5 className="text-nowrap">Email Notification</h5>
        </div>
        <div className="col-md-8 px-0">
          <div className="row justify-content-md-end align-items-center mx-0">
            <div className="col-10 col-md-auto pe-1 px-0">
              <Search
                allowClear
                onChange={handleSearch}
                placeholder="Search "
                value={filterValue.search}
              />
            </div>
            <div className="col-auto ms-0 px-0 antdBtn">
              {buttonPermission?.add && (
                <Button
                  title="Add"
                  type="primary"
                  htmlType="button"
                  onClick={() => toggleModal('Add', null)}
                  icon={<MdAdd size={20} />}
                ></Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="mt-3">
          <Row>
            <Col sm={12}>
              <TmFilter
                filterArray={filterArray}
                handleFilterChange={handleFilterChange}
              />
            </Col>
            <Col md={12}>
              <div>
                <TmMasterTable
                  buttonPermission={buttonPermission}
                  columns={emailComponentHeader}
                  rows={users}
                  custom={['action']}
                  toggleModal={toggleModal}
                  handleDelete={callDelete}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <span>
        <AntdModalComp
          width={800}
          toggleModal={() => toggleModal('Close', null)}
          modalOpen={modal}
          height={MODAL_HEIGHT.md}
          modalHeader={modalHeader}
          modalBody={
            <AddEmail
              modalHeader={modalHeader}
              modaldata={modalData}
              getEmailList={getEmailList}
              setModal={setModal}
              modal={modal}
            />
          }
        />
      </span>
    </>
  );
}

export default EmailComponent;
