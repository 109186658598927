import { Button, Form, Input } from 'antd';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/AuthContext';
import { removeAuthInfo } from '../../Context/AuthSessionStorage';
import { toastError, toastSuccess } from '../Toast/ToastComponent';
import { DOMAIN_HOST } from '../../config/appConfig';
import LoginApi from '../../Api/LoginApi';
import { GENERAL_REGEX } from '../../constant/constant';

const customizeRequiredMark = (label, { required }) => <>{label}</>;
const ChangePassword = ({ toggleModal, modalData }) => {
  const [form] = Form.useForm();
  const { authState } = useAuth();

  const handlePassChange = async (data) => {
    let reqData = { id: authState?.id, ...data };
    try {
      let response = await LoginApi.changePassword(reqData);
      if (response?.data?.success) {
        toastSuccess(response.data.message);
        removeAuthInfo();
        window.close();
        window.open(DOMAIN_HOST, '_self');
      } else {
        toastError(response?.data?.message);
        return;
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  return (
    <div className="d-flex flex-column m-0 justify-content-center align-item-center">
      <div className="mt-4">
        <div className="row justify-content-center">
          <div className="">
            <Form
              name="basic"
              form={form}
              requiredMark={customizeRequiredMark}
              layout="vertical"
              onFinish={handlePassChange}
              autoComplete="off"
            >
              <Form.Item
                label="Old Password"
                name="currentpassword"
                rules={[
                  {
                    pattern: GENERAL_REGEX.password,
                    message: 'Please enter valid password',
                  },
                  { required: true, message: 'Please enter your old password' },
                ]}
              >
                <Input.Password size="large" placeholder="Enter Old Password" />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 35 }}
                label="New Password"
                name="newpassword"
                rules={[
                  {
                    pattern: GENERAL_REGEX.password,
                    message: 'Please enter valid password',
                  },
                  { required: true, message: 'Please enter your new password' },
                  ({ getFieldValue }) => ({
                    validator(_, value, confrm) {
                      if (!value) {
                        confrm();
                      } else if (
                        !value ||
                        getFieldValue('password') === value
                      ) {
                        confrm('The new and old password cannot be same');
                      }
                      confrm();
                    },
                  }),
                ]}
              >
                <Input.Password size="large" placeholder="Enter New Password" />
              </Form.Item>
              <div className="text-center antdBtn">
                <Button
                  type="default"
                  size="medium"
                  htmlType="button"
                  onClick={toggleModal}
                >
                  Cancel
                </Button>
                <Button type="primary" size="medium" htmlType="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
