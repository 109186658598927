import React from 'react';
import { Card, Table } from 'react-bootstrap';
import style from '../../Styles/ProjectTrackingModel.module.css';
const ProjectTrackingmodelcomp = (props) => {
  const { toggleModal, value } = props;

  function calculateTotalHoursExcludingProjectID(data) {
    const parseHours = (value) => {
      if (!value) return 0;
      return parseFloat(value.replace('hrs', '').replace('hr', '').trim());
    };

    const totalHours = Object.entries(data).reduce((acc, [key, value]) => {
      if (!['username', 'employeeCode', 'projectID'].includes(key)) {
        return acc + parseHours(value);
      }
      return acc;
    }, 0);

    return totalHours.toFixed(2);
  }

  return (
    <>
      <div>
        <div>
          {' '}
          <Table responsive>
            <thead>
              <tr>
                <th>S.No</th> <th>Project Name</th>{' '}
                <th>
                  {`Total Hours`}{' '}
                  <span style={{ color: ' #7d8189' }}>
                    ({calculateTotalHoursExcludingProjectID(value)} hrs)
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(value)
                .filter(
                  (element) =>
                    !['projectID', 'employeeCode', 'username'].includes(
                      element,
                    ),
                )
                .map((item, index) => (
                  <tr>
                    <td key={index}>{index + 1}</td>
                    <td key={index}>{item}</td>
                    <td key={index}>{value[item]}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
      {/* <div>
        <div className="row">
          {Object.keys(value).map(
            (item, index) =>
              item !== 'username' && (
                <div className="col-6">
                  <Card>
                    <div className="px-2 ">
                      <div>
                        Project Name : <span>{item}</span>
                      </div>
                      <div>
                        Total Hours : <span>{value[item]}</span>
                      </div>
                    </div>
                  </Card>{' '}
                </div>
              ),
          )}
        </div>
      </div> */}
    </>
  );
};

export default ProjectTrackingmodelcomp;
