import { useNavigate } from 'react-router-dom';
import { toastInfo } from '../Components/Toast/ToastComponent';

export function usePageAccess() {
  const navigate = useNavigate();

  const pageAccess = (data, edit_id) => {
    for (const element in data) {
      if (element === 'actions') {
        for (const action of data[element]) {
          if (!edit_id) {
            if (action.statename == 'Add') {
              if (!action.checked) {
                navigate('/');
              }
            }
          }
          if (edit_id) {
            if (action.statename == 'Edit') {
              if (!action.checked) {
                navigate('/');
              }
            }
          }
        }
      }
    }
  };

  return pageAccess;
}
