import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ProjectApi } from '../../Api/ProjectApi';
import { useAuth } from '../../Context/AuthContext';
import { toastError } from '../Toast/ToastComponent';
import { MdAdd } from 'react-icons/md';
import { Button } from 'antd';
import TmMasterTable from '../ReusableComponents/TmMasterTable';
import { LuDownload } from 'react-icons/lu';
import {
  downloadExcel,
  projectTrackingReport,
} from '../ReusableComponents/downloadExcel';
import Search from 'antd/es/transfer/search';
import TmFilter from '../ReusableComponents/TmFilter';
import useFilterStateData from '../../customHooks/useFilterStateData';
import { MONTHS } from '../../constant/constant';
import { generateYears } from '../../ReusableFn/reusableFn';
import AntdModalComp from '../ReusableComponents/AntdModalComp';
import ProjectTrackingmodelcomp from './ProjectTrackingmodelcomp';
import ProjectTrackingTable from './ProjectTrackingTable';
import { getColumnFilter } from '../../store/reducers/filterState';
import { useSelector } from 'react-redux';

const ProjectTrackingComp = () => {
  const { authState } = useAuth();
  const debounceTimeoutRef = useRef(null);
  let sessionColumn = useSelector(getColumnFilter);

  const [columnFilter, setColumnFilter] = useState(sessionColumn);

  const [modalData, setModalData] = useState({
    value: undefined,
    modal: false,
  });

  const [trackData, setTrackData] = useState({
    columns: [],
    rows: [],
  });

  const { month: filterStateMonth, year: filterStateYear } =
    useFilterStateData();
  const [filterValue, setfilterValue] = useState({
    search: '',
    month: filterStateMonth,
    year: filterStateYear,
  });
  // let filterValue = {
  //   search: '',
  //   month: filterStateMonth,
  //   year: filterStateYear,
  // };

  const filterArray = [
    {
      options: MONTHS,
      optionFilterProp: 'label',
      placeholder: 'Month',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: filterStateMonth,
      controlName: 'month',
      allowClear: false,
      input: 'select',
    },
    {
      options: generateYears() || [],
      optionFilterProp: 'label',
      placeholder: 'Year',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: filterStateYear,
      controlName: 'year',
      allowClear: false,
      input: 'select',
    },
  ];
  const handleFilterChange = async (obj) => {
    // filterValue = { ...filterValue, ...obj } || {};
    setfilterValue((pre) => ({ ...pre, ...obj }));

    getTrackingdata({ ...filterValue, ...obj });
  };
  const getTrackingdata = async (obj) => {
    try {
      let response = await ProjectApi.getProjectTracking({
        user_id: authState?.id,
        userRoleID: authState?.userRoleID,
        ...obj,
      });

      if (response?.data?.success) {
        let headerDisplay = response?.data?.result?.headerResult;
        headerDisplay = headerDisplay.map((item) => ({
          ...item,
          isChecked: true,
          ['headerName']: `${item.headerName.toUpperCase()}`,
        }));

        if (headerDisplay?.length) {
          headerDisplay = [
            {
              field: 'username',
              headerName: 'USER NAME',
              isChecked: true,
              isDisabled: true,
            },
            {
              field: 'employeeCode',
              headerName: 'EMPLOYEE CODE',
              isChecked: true,
              isDisabled: true,
            },
            ...headerDisplay,
          ];
        }

        let rowDisaplay = response?.data?.result?.userResult || [];
        rowDisaplay = rowDisaplay.map((item) => {
          let getKeys = Object.keys(item);
          let newObj = {};
          getKeys.forEach((itm) =>
            itm == 'username' || itm == 'employeeCode'
              ? (newObj[itm] = item[itm])
              : (newObj[itm] =
                  item[itm] + ' ' + (Number(item[itm]) <= 1 ? 'hr' : 'hrs')),
          );
          return newObj;
        });

        if (!sessionColumn?.length) {
          setColumnFilter(headerDisplay || []);
        }

        setTrackData({
          rows: rowDisaplay || [],
          columns: headerDisplay || [],
        });
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  const handleDownload = async () => {
    let downloadData = {
      fileName: `Project Tracking ${filterValue.month}-${filterValue.year}.xlsx`,
      sheetName: '',
    };
    downloadData[
      'excelHeadr'
    ] = `Project Tracking ${filterValue.month}-${filterValue.year}`;
    downloadData['sheetName'] =
      `${filterValue.month}-${filterValue.year}` || 'sheet 1';
    downloadData['columns'] = columnItems || [];
    downloadData['rows'] = trackData.rows || [];
    projectTrackingReport(downloadData);
  };

  const outerFn = (() => {
    let outData;
    return function innerFn() {
      clearTimeout(outData);
      outData = setTimeout(() => {
        getTrackingdata(filterValue);
      }, 600);
    };
  })();
  const handleSearch = (e) => {
    let value = e.target.value.trim();

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      handleFilterChange({ ...filterValue, search: value });
    }, 400);
  };

  /*************  ✨ Codeium Command ⭐  *************/
  /**
   * @function
   * @description Toggle the modal state and update the value to be
   *              passed to the modal component
   * @param {string} type - The type of modal to open
   * @param {object} value - The value to be passed to the modal component
   * @param {event} e - The event of the element that triggered the function
   * @returns {object} An object with modal state and value
   */
  /******  d11a0760-9091-4c5d-aeac-32fc59bd22af  *******/
  const toggleModal = (type, value, e) => {
    setModalData((prev) => ({
      modal: !prev.modal,
      value: value,
    }));
  };

  useEffect(() => {
    getTrackingdata(filterValue);
  }, [sessionColumn]);

  const columnItems = useMemo(() => {
    return columnFilter.filter((element) => element?.isChecked);
  }, [columnFilter, trackData, sessionColumn]);

  return (
    <>
      <div>
        <div className="d-md-flex">
          <div>
            {' '}
            <h5 className="text-nowrap">Project Tracking</h5>
          </div>
          <div className="ms-md-auto d-flex ">
            <div className=" me-2">
              {' '}
              <Search
                allowClear
                onChange={handleSearch}
                placeholder="Search "
              />
            </div>
            <div>
              <span>
                <Button
                  title="Download"
                  type="dashed"
                  htmlType="button"
                  onClick={handleDownload}
                  icon={<LuDownload size={20} />}
                >
                  Download
                </Button>
              </span>
            </div>
          </div>
        </div>

        <div className="mt-2 mb-0">
          <TmFilter
            resetColumn
            columnFilter={columnFilter}
            setColumnFilter={setColumnFilter}
            filterArray={filterArray}
            handleFilterChange={handleFilterChange}
          />
        </div>
        <div>
          <ProjectTrackingTable
            rows={trackData.rows}
            columns={columnItems}
            custom={[]}
            toggleModal={toggleModal}
          />
        </div>
      </div>
      <AntdModalComp
        width="40%"
        height={350}
        toggleModal={toggleModal}
        modalOpen={modalData.modal}
        modalHeader={<>{`${modalData?.value?.username}'s Project Hours`}</>}
        modalBody={<ProjectTrackingmodelcomp value={modalData.value} />}
      />
    </>
  );
};

export default ProjectTrackingComp;
