import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import {
  Form,
  Input,
  Button,
  Checkbox,
  Select,
  DatePicker,
  TimePicker,
  InputNumber,
  Switch,
  Dropdown,
  Menu,
} from 'antd';
import { toastSuccess, toastError, toastInfo } from '../Toast/ToastComponent';
import style from '../../Styles/TmModule.module.css';
import dayjs from 'dayjs';
import Mandatory from '../ReusableComponents/Mandatory';
import { Row, Col } from 'reactstrap';
import Module from '../../Api/Module';
import { ProjectApi } from '../../Api/ProjectApi';
import {
  DateFormat,
  DisplayDateFormat,
  HISTORY_INPUTS,
  MODULE_STATUS,
} from '../../constant/constant';
import {
  dateFormatToDayjs,
  dayjsToDateFormat,
} from '../../ReusableFn/reusableFn';
import { useAuth } from '../../Context/AuthContext';
import { IoIosTimer } from 'react-icons/io';
import { TbHours12 } from 'react-icons/tb';
import { FaRegCircleXmark } from 'react-icons/fa6';
import HistoryModal from '../ReusableComponents/HistoryModal';
import ModalComponent from '../ReusableComponents/ModalComponent';
import { BsFillHSquareFill } from 'react-icons/bs';
import EditFieldModal from '../ReusableComponents/EditFieldModal';
import { FiEdit } from 'react-icons/fi';
import { MdAdd } from 'react-icons/md';

const { Option } = Select;
const format = 'HH:mm';

dayjs.extend(require('dayjs/plugin/buddhistEra'));

const AddModuleComponent = ({
  getData,
  modaldata,
  modal,
  setModal,
  modalHeader,
}) => {
  const [projectList, setProjectList] = useState([]);
  const [members, setMembers] = useState([]);

  const [hours, setHours] = useState({
    totalModuleHours: 0,
    totalProjectHours: 0,
    balanceHours: 0,
    billing_method: '',
  });

  const [historyModal, setHistoryModal] = useState({
    modalLabel: undefined,
    isOpen: false,
    key: undefined,
  });

  const [historyData, setHistoryData] = useState([]);

  const [arrayData, setArrayData] = useState({
    value: [],
    count: 1,
  });

  const [newModal, setNewModal] = useState({
    isOpen: false,
    fields: undefined,
  });

  const [check, setCheck] = useState([]);

  const navigate = useNavigate();
  const { authState } = useAuth();
  const [form] = Form.useForm();
  const [feildControls, setFeildControls] = useState({
    setMinDate: '',
    setMaxDate: '',
  });
  const draftModuleEdit = modalHeader?.props?.children === 'Edit Draft Module';
  const draftModuleView = modalHeader?.props?.children === 'View Draft Module';
  const moduleView =
    modalHeader?.props?.children == 'View Module' || draftModuleView;

  let initialValues = {
    project_id: null,
    module_name: null,
    start_date: null,
    start_time: null,
    end_date: null,
    end_time: null,
    checkData: false,
    convertModule: false,
    estimated_hours: null,
    module_status: 'NOT YET STARTED',
    access_members: [],
  };

  const customizeRequiredMark = (label, { required }) => (
    <>
      {label}
      {required && <Mandatory />}
    </>
  );

  const handleEditData = async () => {
    await getProjectUsers(modaldata.project_id);
    const values = {};
    for (const element in modaldata) {
      if (
        element == 'start_date' ||
        element == 'end_date' ||
        element == 'start_time' ||
        element == 'end_time'
      ) {
        values[element] = modaldata[element] ? dayjs(modaldata[element]) : '';
      } else if (element == 'access_members') {
        values[element] = modaldata[element]
          ? JSON.parse(modaldata[element])
          : [];
      } else {
        values[element] = modaldata[element];
      }
    }

    form.setFieldsValue(values);
  };

  const handleCancel = async () => {
    form.resetFields();

    setModal(!modal);
  };

  const onSubmit = async (data) => {
    try {
      // data.start_date =
      //   data.start_date && dayjs(data.start_date).format('DD-MM-YYYY');
      // data.end_date =
      //   data.end_date && dayjs(data.end_date).format('DD-MM-YYYY');

      // if (data.access_members == undefined) {
      //   toastInfo("Please Add Access members");
      //   return;
      // }

      // if (historyData?.length) {
      //   for (const element of historyData) {
      //     element['updatedValue'] = data[element?.fieldType];
      //     element.updatedBy = Number(authState?.id);
      //   }
      // }

      if (historyData?.length) {
        historyData.forEach(({ fieldType, updatedValue }) => {
          if (fieldType === 'end_date') data.end_date = updatedValue;
          if (fieldType === 'estimated_hours')
            data.estimated_hours = updatedValue;
        });
      }

      delete data.checkData;

      data.access_members = data.access_members
        ? JSON.stringify(data.access_members)
        : null;

      if (modaldata.hasOwnProperty('id')) {
        let newArrayData = [...arrayData?.value];

        let lastElement = newArrayData[newArrayData?.length - 1];

        const hasNonNullValue = Object.values(lastElement).some(
          (field) => field.value !== null,
        );

        if (hasNonNullValue) {
          Object.values(lastElement).forEach((field) => {
            field.isEdit = false;
          });
          data.arrayData = JSON.stringify(newArrayData);
        }

        data.id = modaldata?.id;

        if (historyData?.length) {
          data.historyData = JSON.stringify(historyData);
        }
      }

      if (draftModuleEdit || draftModuleView) {
        data.isDraft = data?.convertModule ? false : true;
        delete data?.convertModule;
      }

      data.user_id = authState?.id;

      const response = modaldata.hasOwnProperty('id')
        ? await Module.update(data)
        : await Module.create(data);
      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        getData();
        form.resetFields();
        setModal(!modal);
        setMembers([]);
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  // const onSubmit = async (data) => {
  //   try {
  //     // data.start_date =
  //     //   data.start_date && dayjs(data.start_date).format('DD-MM-YYYY');
  //     // data.end_date =
  //     //   data.end_date && dayjs(data.end_date).format('DD-MM-YYYY');

  //     // if (data.access_members == undefined) {
  //     //   toastInfo("Please Add Access members");
  //     //   return;
  //     // }

  //     if (historyData?.length) {
  //       for (const element of historyData) {
  //         element['updatedValue'] = data[element?.fieldType];
  //         element.updatedBy = Number(authState?.id);
  //       }
  //     }

  //     delete data.checkData;

  //     data.access_members = data.access_members
  //       ? JSON.stringify(data.access_members)
  //       : null;

  //     if (modaldata.hasOwnProperty('id')) {
  //       data.id = modaldata?.id;
  //       if (historyData?.length) {
  //         data.historyData = JSON.stringify(historyData);
  //       }
  //     }

  //     if (draftModuleEdit || draftModuleView) {
  //       data.isDraft = data?.convertModule ? false : true;
  //       delete data?.convertModule;
  //     }

  //     data.user_id = authState?.id;

  //     const response = modaldata.hasOwnProperty('id')
  //       ? await Module.update(data)
  //       : await Module.create(data);
  //     if (response?.data?.success) {
  //       toastSuccess(response?.data?.message);
  //       getData();
  //       form.resetFields();
  //       setModal(!modal);
  //       setMembers([]);
  //     } else {
  //       toastError(response?.data?.message);
  //     }
  //   } catch (error) {
  //     //toastError(error.response.data.message);
  //     toastError(error);
  //   }
  // };

  const getDropDown = async () => {
    try {
      const response = await ProjectApi.dropDown({
        id: authState.id,
        userRoleID: authState?.userRoleID,
      });
      if (response?.data?.success) {
        setProjectList(response?.data?.result);
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  const calculateHr = (type = 'update') => {
    const { end_date, start_date, start_time, end_time } =
      form.getFieldsValue();
    if (type == 'reset') {
      setFeildControls((pre) => ({ ...pre, setMinDate: start_date }));
      form.setFieldValue('end_date', '');
    }
    if (end_date && start_date && start_time && end_time) {
      let calDays =
        (end_date.valueOf() - start_date.valueOf()) / (1000 * 60 * 60);
      let calHrs = 24 - start_time.get('hour') + end_time.get('hour');
      let overAll = calDays + calHrs - 24;
      overAll = Number(overAll).toFixed(2);
      overAll = parseFloat(overAll);

      // form.setFieldValue('estimated_hours', overAll > 0 ? overAll : 0);
    } else {
      // form.setFieldValue('estimated_hours', null);
    }
  };

  const getProjectUsers = async (id) => {
    checkAll({});
    form.setFieldValue('checkData', false);
    try {
      const response = await Module.getProjectUsers(id);

      if (response?.data?.success) {
        setHours((prev) => ({
          ...prev,
          totalModuleHours: response?.data?.result?.totalModuleHours,
          totalProjectHours: response?.data?.result?.totalProjectHours,
          balanceHours: response?.data?.result?.balanceHours,
          billing_method: response?.data?.result?.billing_method,
        }));
        setMembers(response?.data?.result?.users);
        let parseData = !!modaldata?.access_members
          ? JSON.parse(modaldata?.access_members)
          : '{}';
        if (
          parseData?.length === response?.data?.result?.length &&
          modaldata.hasOwnProperty('id')
        ) {
          form.setFieldValue('checkData', true);
        }
        form.setFieldValue('access_members', undefined);
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  const validateInput = (_, value) => {
    if (value !== value.trim() || value.includes('  ')) {
      return Promise.reject(new Error(' Extra spaces are not allowed'));
    }

    return Promise.resolve();
  };

  const checkAll = (e) => {
    if (members.length <= 0) return;
    let getMembersId = members.map((item) => item.id);
    if (!!e?.target?.checked) {
      form.setFieldValue('access_members', getMembersId);
      return;
    }
    form.setFieldValue('access_members', []);
  };

  const handleCheck = (e) => {
    let allAccessMembers = form.getFieldValue('access_members') || [];
    let countAccessMembers = e.target.checked === true ? 1 : -1;
    if (allAccessMembers?.length + 1 === members.length) {
      form.setFieldValue('checkData', true);
    } else {
      form.setFieldValue('checkData', false);
    }
  };

  const checkCustom = async (e) => {
    let value = e?.target?.checked;

    const module_name = form.getFieldValue('module_name');

    if (value == true) {
      if (modaldata.hasOwnProperty('id')) {
        let reqData = {
          module_id: modaldata?.id || 0,
          module_name: module_name,
        };

        try {
          const response = await Module.customModule(reqData);

          if (response?.data?.success) {
            toastSuccess(response?.data?.message);
            form.setFieldValue(
              'convertModule',
              response?.data?.result?.isDraft,
            );
          } else {
            toastInfo(response?.data?.message);
            form.setFieldValue(
              'convertModule',
              response?.data?.result?.isDraft,
            );
          }
        } catch (error) {
          //toastError(error.response.data.message);
          toastError(error);
        }
      }
    }
  };

  const toggleModal = (data, label, key) => {
    if (!label) {
      let value =
        ['start_date', 'end_date'].includes(historyModal.key) &&
        modaldata[historyModal.key]
          ? dayjs(modaldata[historyModal.key])
          : modaldata[historyModal.key] || '';

      form.setFieldValue(historyModal?.key, value);
      form.setFields([
        {
          name: historyModal?.key,
          errors: [],
        },
      ]);
    }

    let hasKey = historyData?.some((element) => {
      if (HISTORY_INPUTS.includes(key) && element.fieldType == key) {
        return true;
      }
    });

    if (!hasKey) {
      setHistoryModal((prev) => ({
        ...prev,
        modalLabel: label,
        isOpen: !prev.isOpen,
        key,
      }));
    }
  };

  const handleHistoryData = (key, value) => {
    let array = [...historyData];
    let indexValue = array.findIndex((element) => element.fieldType == key);
    indexValue !== -1 && array.splice(indexValue, 1);
    value && array.push(value);
    setHistoryData(array);
    setHistoryModal({ modalLabel: undefined, isOpen: false, key: undefined });
  };

  const handleRevert = async (key) => {
    form.setFieldValue(
      key,
      ['start_date', 'end_date'].includes(key)
        ? dayjs(modaldata[key])
        : modaldata[key],
    );
    handleHistoryData(key);
  };

  const getProjectHistory = async () => {
    try {
      const response = await ProjectApi.getProjectHistoryTable({
        module_id: modaldata?.id,
      });
      if (response?.data?.success) {
        const { moduleHistory } = response.data.result;
        setCheck(moduleHistory);
      }
    } catch (error) {
      toastError(error);
    }
  };

  const getMenuItems = (type) => {
    let isThere = check?.some((element) => element?.logType === type);
    const dropdownItems = isThere
      ? [
          {
            key: '1',
            label: <b>History</b>,
          },
          {
            key: '2',
            label: (
              <div className={style.historyScrolable}>
                <ul>
                  {check?.map((item) => {
                    if (item?.logType === type) {
                      return (
                        <li key={item?.id} className="p-2">
                          <b>{item?.logType}</b> - {item?.logContent}{' '}
                          <span style={{ color: '#646871' }}>
                            -{' '}
                            {dayjs(
                              item.createdAt,
                              'DD-MM-YYYY h:mm A',
                            ).fromNow()}
                          </span>
                        </li>
                      );
                    }
                    return null;
                  })}
                </ul>
              </div>
            ),
          },
        ]
      : [];

    return dropdownItems;
  };

  const handleLogs = () => {
    if (modaldata?.arrayData?.length) {
      setArrayData((prev) => ({
        ...prev,
        value: modaldata?.arrayData,
        count: 1,
      }));
    } else {
      let checkArray = [];
      let obj = {
        start_date: {
          value: modaldata?.start_date,
          fieldType: 'start_date',
          label: 'Start Date',
          isModified: false,
          isEdit: false,
          reason: '',
          isDateField: true,
        },
        end_date: {
          value: modaldata?.end_date,
          fieldType: 'end_date',
          label: 'End Date',
          minDate: modaldata?.start_date,
          isModified: false,
          isEdit: false,
          reason: '',
          isDateField: true,
        },
        estimated_hours: {
          value: modaldata?.estimated_hours,
          addedValue: modaldata?.estimated_hours,
          fieldType: 'estimated_hours',
          label: 'Estimated Hours',
          isModified: false,
          isEdit: false,
          reason: '',
          isDateField: false,
        },
      };
      checkArray.push(obj);
      setArrayData((prev) => ({
        ...prev,
        value: checkArray,
        count: 1,
      }));
    }
  };

  const toggleNewModal = (data) => {
    setNewModal((prev) => ({
      ...prev,
      isOpen: !prev.isOpen,
      fields: data,
    }));
  };

  const appendData = () => {
    let checkArray = [...arrayData?.value];

    let obj = {
      start_date: {
        value: null,
        fieldType: 'start_date',
        label: 'Start Date',
        isModified: false,
        isEdit: true,
        reason: '',
        isDateField: true,
      },
      end_date: {
        value: null,
        fieldType: 'end_date',
        minDate: modaldata?.start_date,
        label: 'End Date',
        isModified: false,
        isEdit: true,
        reason: '',
        isDateField: true,
      },
      estimated_hours: {
        value: null,
        addedValue: null,
        fieldType: 'estimated_hours',
        label: 'Estimated Hours',
        isModified: false,
        isEdit: true,
        reason: '',
        isDateField: false,
      },
    };

    checkArray.push(obj);

    if (arrayData?.count == 1) {
      setArrayData((prev) => ({
        ...prev,
        value: checkArray,
        count: 2,
      }));
    } else {
      toastInfo('Values have not been updated yet.');
    }
  };

  useEffect(() => {
    getDropDown();
    if (modaldata?.hasOwnProperty('id')) {
      handleEditData();
      getProjectHistory();
      handleLogs();
    }
  }, [modaldata, form]);

  return (
    <div className="addmodule1">
      <Form
        onFinish={onSubmit}
        initialValues={initialValues}
        layout="vertical"
        form={form}
        requiredMark={customizeRequiredMark}
      >
        <Row gutter={16} className="m-0">
          <Col md={6}>
            <Form.Item
              name="project_id"
              label="Project"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Select
                onChange={(value) => getProjectUsers(value)}
                disabled={modaldata.hasOwnProperty('id')}
                // disabled={
                //   moduleView ||
                //   draftModuleEdit ||
                //   draftModuleView ||
                //   modaldata.hasOwnProperty('id')
                // }
                type="select"
                placeholder="Select"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {projectList?.map((element) => (
                  <Option key={element.value} value={element.value}>
                    {element.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {!(draftModuleEdit || draftModuleView || moduleView) && (
              <div className="row m-md-0">
                <div className="col-md-6 m-md-0 p-md-0 pe-md-1 ">
                  <div
                    className={`d-flex  align-items-center p-2  ${style.totalhourscolor}`}
                  >
                    <span className="pe-1">
                      <IoIosTimer size={24} />
                    </span>
                    <div
                      style={{
                        fontSize: 14,
                      }}
                      className="text-nowrap overflow-hidden"
                    >
                      Total Hours :
                    </div>
                    <div
                      className="px-1  text-nowrap overflow-hidden "
                      style={{
                        fontWeight: 'bold',
                        // overflow: 'hidden',
                        // whiteSpace: 'nowrap',
                        // textOverflow: 'ellipsis',
                        // maxWidth: '100px',
                      }}
                    >
                      {hours.totalProjectHours == null
                        ? 0
                        : hours.totalProjectHours}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-md-0 p-md-0 mt-md-0 mt-2  ">
                  {' '}
                  <div
                    className={`d-flex  align-items-center p-2  ${style.totalhourscolor}`}
                  >
                    <span className="pe-1">
                      <TbHours12 size={24} />
                    </span>
                    <div
                      style={{
                        fontSize: 14,
                      }}
                      className="text-nowrap overflow-hidden"
                    >
                      Balance Hours :{' '}
                    </div>
                    <div
                      className="px-1 text-nowrap overflow-hidden"
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      {hours.balanceHours == null ? 0 : hours.balanceHours}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <Form.Item
              className="pt-2"
              name="module_name"
              label="Module"
              rules={[
                { required: true, message: 'This field is required' },
                {
                  validator: validateInput,
                },
              ]}
            >
              <Input
                onChange={() => form.setFieldValue('convertModule', false)}
                disabled={moduleView}
                placeholder="Enter Module"
              />
            </Form.Item>
            <div
              className={`${
                modaldata?.hasOwnProperty('id')
                  ? 'd-none'
                  : 'row justify-content-between'
              }`}
            >
              <div className="col-md-6">
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Item
                    className="w-100 m-0"
                    name="start_date"
                    label="Start Date"
                    // rules={[
                    //   ...(hours?.billing_method !== 'FREE' && !draftModuleEdit
                    //     ? [
                    //         {
                    //           required: true,
                    //           message: 'This field is required',
                    //         },
                    //       ]
                    //     : []),
                    // ]}
                  >
                    <DatePicker
                      disabled={moduleView}
                      inputReadOnly={true}
                      className="w-100"
                      onChange={
                        modaldata.hasOwnProperty('id')
                          ? (data) => {
                              toggleModal(data, 'start date', 'start_date');
                              calculateHr('reset');
                            }
                          : () => calculateHr('reset')
                      }
                      // format={DateFormat}
                      format={DisplayDateFormat}
                      // onChange={() => calculateHr('reset')}
                    />
                  </Form.Item>

                  {modaldata.hasOwnProperty('id') &&
                    !(draftModuleEdit || draftModuleView || moduleView) && (
                      <Button
                        onClick={() => handleRevert('start_date')}
                        disabled={
                          !historyData?.some(
                            (element) => element?.fieldType == 'start_date',
                          )
                        }
                        className="border-0   bg-transparent p-0 pt-3 px-1"
                      >
                        <FaRegCircleXmark size={20} />
                      </Button>
                    )}
                </div>
                <div>
                  {getMenuItems('START DATE')?.length > 0 &&
                    !(draftModuleEdit || draftModuleView || moduleView) && (
                      <div className="mt-1">
                        <span className="supported_text">
                          <Dropdown
                            overlay={
                              <Menu items={getMenuItems('START DATE')} />
                            }
                            //menu={{ items: dropdownItems?.endDate }}
                            placement="topLeft"
                            arrow
                          >
                            <button
                              className="btn m-0 p-0 border-none"
                              type="button"
                            >
                              <BsFillHSquareFill size={16} color="#a1b0d3" />
                              <small>&nbsp;History</small>
                            </button>
                          </Dropdown>
                        </span>
                      </div>
                    )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Item
                    className="w-100 m-0"
                    name="end_date"
                    label="End Date"
                    // rules={[
                    //   ...(hours?.billing_method !== 'FREE' && !draftModuleEdit
                    //     ? [
                    //         {
                    //           required: true,
                    //           message: 'This field is required',
                    //         },
                    //       ]
                    //     : []),
                    // ]}
                  >
                    <DatePicker
                      disabled={moduleView}
                      inputReadOnly={true}
                      className="w-100"
                      minDate={feildControls.setMinDate}
                      onChange={
                        modaldata.hasOwnProperty('id')
                          ? (data) => {
                              toggleModal(data, 'end date', 'end_date');
                              calculateHr();
                            }
                          : () => calculateHr()
                      }
                      // format={DateFormat}
                      format={DisplayDateFormat}
                      // onChange={calculateHr}
                    />
                  </Form.Item>
                  {modaldata.hasOwnProperty('id') &&
                    !(draftModuleEdit || draftModuleView || moduleView) && (
                      <Button
                        onClick={() => handleRevert('end_date')}
                        disabled={
                          !historyData?.some(
                            (element) => element?.fieldType == 'end_date',
                          )
                        }
                        className="border-0   bg-transparent p-0 pt-3 px-1"
                      >
                        <FaRegCircleXmark size={20} />
                      </Button>
                    )}
                </div>
                <div>
                  {getMenuItems('END DATE')?.length > 0 &&
                    !(draftModuleEdit || draftModuleView || moduleView) && (
                      <div className="mt-1">
                        <span className="supported_text">
                          <Dropdown
                            overlay={<Menu items={getMenuItems('END DATE')} />}
                            //menu={{ items: dropdownItems?.endDate }}
                            placement="topLeft"
                            arrow
                          >
                            <button
                              className="btn m-0 p-0 border-none"
                              type="button"
                            >
                              <BsFillHSquareFill size={16} color="#a1b0d3" />
                              <small>&nbsp;History</small>
                            </button>
                          </Dropdown>
                        </span>
                      </div>
                    )}
                </div>
              </div>
              {/* <div className="col-6">
                <Form.Item
                  className="w-100"
                  name="start_time"
                  label={<>&nbsp;</>}
                >
                  <TimePicker
                    disabled={moduleView}
                    inputReadOnly={true}
                    className="w-100"
                    format="h:mm A"
                    placeholder="12.00 AM"
                    use12Hours
                    onChange={calculateHr}
                  />
                </Form.Item>
              </div> */}
            </div>
            {/* <div className="row justify-content-between">
              <div className="col-6">
                <Form.Item className="w-100" name="end_date" label="End Date">
                  <DatePicker
                    disabled={moduleView}
                    inputReadOnly={true}
                    className="w-100"
                    minDate={feildControls.setMinDate}
                    // format={DateFormat}
                    format={DisplayDateFormat}
                    onChange={calculateHr}
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  className="w-100"
                  name="end_time"
                  label={<>&nbsp;</>}
                >
                  <TimePicker
                    disabled={moduleView}
                    inputReadOnly={true}
                    className="w-100"
                    format="h:mm A"
                    placeholder="12.00 AM"
                    use12Hours
                    onChange={calculateHr}
                  />
                </Form.Item>
              </div>
            </div> */}
            <div
              className={`${modaldata?.hasOwnProperty('id') ? 'd-none' : ''}`}
            >
              <div className="d-flex mt-3 justify-content-center align-items-center">
                <Form.Item
                  name="estimated_hours"
                  label="Estimated Hours"
                  className="w-100 m-0"
                  rules={[
                    // ...(hours?.billing_method !== 'FREE' && !draftModuleEdit
                    //   ? [
                    //       {
                    //         required: true,
                    //         message: 'This field is required',
                    //       },
                    //     ]
                    //   : []),
                    {
                      type: 'number',
                      min: 0,
                      ...(hours?.balanceHours && !modaldata.hasOwnProperty('id')
                        ? { max: hours.balanceHours }
                        : {}),
                      message: 'The balance hours have been exceeded.',
                    },
                  ]}
                >
                  <InputNumber
                    disabled={moduleView}
                    className="w-100"
                    placeholder="0:00"
                    onChange={
                      modaldata.hasOwnProperty('id')
                        ? (data) =>
                            toggleModal(
                              data,
                              'estimated hours',
                              'estimated_hours',
                            )
                        : undefined
                    }
                    // min={0}
                    type="number"
                  />
                </Form.Item>
                {modaldata.hasOwnProperty('id') &&
                  !(draftModuleEdit || draftModuleView || moduleView) && (
                    <Button
                      onClick={() => handleRevert('estimated_hours')}
                      disabled={
                        !historyData?.some(
                          (element) => element?.fieldType == 'estimated_hours',
                        )
                      }
                      className="border-0   bg-transparent p-0 pt-3 px-1"
                    >
                      <FaRegCircleXmark size={20} />
                    </Button>
                  )}
              </div>
              {getMenuItems('ESTIMATED HOURS')?.length > 0 &&
                !(draftModuleEdit || draftModuleView || moduleView) && (
                  <div className="mt-1">
                    <span className="supported_text">
                      <Dropdown
                        overlay={
                          <Menu items={getMenuItems('ESTIMATED HOURS')} />
                        }
                        //menu={{ items: dropdownItems?.endDate }}
                        placement="topLeft"
                        arrow
                      >
                        <button
                          className="btn m-0 p-0 border-none"
                          type="button"
                        >
                          <BsFillHSquareFill size={16} color="#a1b0d3" />
                          <small>&nbsp;History</small>
                        </button>
                      </Dropdown>
                    </span>
                  </div>
                )}
            </div>

            <div className={`  ${style.background4}`}>
              {arrayData?.value?.length > 0 &&
                arrayData?.value?.map((element, index) => (
                  <>
                    <div className="row p-2 ">
                      <div>
                        <div className="col-12 border-bottom mb-1 ">
                          <div className={`m-0 row ${style.fourcardcolor}`}>
                            <div
                              className={`col  border-end   ${style.fourcardcolor}   `}
                            >
                              <div className=" ">
                                <div>Start date</div>
                                <div className="">
                                  <span>
                                    <b>
                                      {element?.start_date?.value
                                        ? dayjs(
                                            element?.start_date?.value,
                                          ).format('DD-MM-YYYY')
                                        : '--'}
                                    </b>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`col  border-end   ${style.fourcardcolor}   `}
                            >
                              <div className="text-center">
                                <div>End date</div>
                                <div>
                                  <span>
                                    <b>
                                      {' '}
                                      {element?.end_date?.value
                                        ? dayjs(
                                            element?.end_date?.value,
                                          ).format('DD-MM-YYYY')
                                        : '--'}
                                    </b>{' '}
                                    <br></br>
                                  </span>
                                  {element?.end_date?.isEdit && (
                                    <button
                                      type="button"
                                      onClick={() =>
                                        toggleNewModal(element?.end_date)
                                      }
                                      className="btn text-primary btn-sm p-0"
                                    >
                                      Add
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>{' '}
                            <div
                              className={`col  border-end   ${style.fourcardcolor}   `}
                            >
                              <div className="text-center">
                                <div>Est. Hrs</div>
                                <div>
                                  <span>
                                    <b>
                                      {' '}
                                      {element?.estimated_hours?.addedValue ||
                                        '--'}
                                    </b>{' '}
                                    <br></br>
                                  </span>
                                  {element?.estimated_hours?.isEdit && (
                                    <button
                                      type="button"
                                      onClick={() =>
                                        toggleNewModal(element?.estimated_hours)
                                      }
                                      className="btn text-primary btn-sm p-0"
                                    >
                                      Add
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>{' '}
                          </div>
                        </div>{' '}
                      </div>
                    </div>
                    {/* {arrayData?.value?.length - 1 === index && (
                            <div
                              style={{ textAlign: 'right' }}
                              className="my-1"
                            >
                              <Button
                                type="primary"
                                onClick={appendData}

                                htmlType="button"
                                icon={<FiEdit size={14} />}
                              >

                              </Button>
                            </div>
                          )} */}
                  </>
                ))}
            </div>
            {arrayData?.value?.length > 0 &&
              !(draftModuleEdit || draftModuleView || moduleView) && (
                <div style={{ textAlign: 'right' }} className="my-1">
                  <button
                    htmlType="button"
                    type="button"
                    className={`border-0  text-primary bg-transparent ${style.fontsizeAdd}`}
                    onClick={appendData}
                  >
                    <MdAdd
                      size={22}
                      className="border-primary border rounded-pill text-primary"
                    />{' '}
                    <span className="pt-2"> Add</span>
                  </button>
                </div>
              )}

            {/* {arrayData?.value?.length > 0 &&
              arrayData?.value?.map((element, index) => (
                <>
                  <div
                    className="d-flex flex-row justify-content-between align-items-start card"
                    style={{
                      width: '100%',
                      border: '1px solid #dfe7f5',
                      padding: '10px',
                      backgroundColor: '#ecf6ff',
                      marginBottom: 5,
                    }}
                  >
                    <div
                      style={{
                        width: 100,

                        padding: '10px',
                      }}
                    >
                      <p className="m-0">Start Date</p>
                      <span>
                        <b>
                          {element?.start_date?.value
                            ? dayjs(element?.start_date?.value).format(
                                'DD-MM-YYYY',
                              )
                            : '--'}
                        </b>
                      </span>
                    </div>
                    <div
                      style={{
                        width: 100,
                        padding: '10px',
                      }}
                    >
                      <p className="m-0">End Date</p>
                      <span>
                        <b>
                          {' '}
                          {element?.end_date?.value
                            ? dayjs(element?.end_date?.value).format(
                                'DD-MM-YYYY',
                              )
                            : '--'}
                        </b>{' '}
                        <br></br>
                      </span>
                      {element?.end_date?.isEdit && (
                        <button
                          type="button"
                          onClick={() => toggleNewModal(element?.end_date)}
                          className="btn btn-link btn-sm p-0"
                        >
                          Edit Field
                        </button>
                      )}
                    </div>
                    <div
                      style={{
                        width: 100,
                        padding: '10px',
                      }}
                    >
                      <p className="m-0">Est. Hrs</p>
                      <span>
                        <b> {element?.estimated_hours?.addedValue || '--'}</b>{' '}
                        <br></br>
                      </span>
                      {element?.estimated_hours?.isEdit && (
                        <button
                          type="button"
                          onClick={() =>
                            toggleNewModal(element?.estimated_hours)
                          }
                          className="btn btn-link btn-sm p-0"
                        >
                          Edit Field
                        </button>
                      )}
                    </div>
                  </div>
                  {!(draftModuleEdit || draftModuleView || moduleView) &&
                    arrayData?.value?.length - 1 === index && (
                      <div style={{ textAlign: 'right' }} className="my-1">
                        <button
                          type="button"
                          onClick={appendData}
                          className="btn btn-primary"
                        >
                          <FiEdit size={16} color="#ffffff" />
                        </button>
                      </div>
                    )}
                </>
              ))} */}

            <Form.Item
              className="mt-3"
              label="Module Status"
              name="module_status"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Select
                disabled={moduleView}
                options={MODULE_STATUS}
                type="select"
                placeholder="Select"
                showSearch
              />
            </Form.Item>

            {(draftModuleEdit || draftModuleView) && (
              <Form.Item name="convertModule" valuePropName="checked">
                <Checkbox
                  disabled={moduleView}
                  onChange={(e) => checkCustom(e)}
                >
                  Modify this module as a new module
                </Checkbox>
              </Form.Item>
            )}
          </Col>
          <Col md={6}>
            <div>
              <div className="d-flex ">
                <div style={{ height: 32 }}>Access</div>
                {/* members.length > 0 && */}
                {
                  <div className="ms-auto">
                    <Form.Item
                      valuePropName="checked"
                      name="checkData"
                      style={{ marginBottom: 0 }}
                    >
                      <Checkbox
                        disabled={moduleView}
                        onClick={(e) => checkAll(e)}
                      >
                        Select All
                      </Checkbox>
                    </Form.Item>
                  </div>
                }
              </div>
              <div className={`${style.addModule} border ps-3`}>
                <Form.Item name="access_members">
                  <Checkbox.Group>
                    <Row>
                      {members?.map((item, index) => (
                        <Col md={12} key={index + 33} className="mt-1">
                          <Checkbox
                            onChange={(e) => handleCheck(e)}
                            disabled={moduleView}
                            value={item.id}
                          >
                            {item.value}
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </div>
            </div>
          </Col>

          {!moduleView && (
            <Row className="mt-3">
              <Col className="d-flex justify-content-center">
                <Button
                  type="default"
                  className={style['btn-space']}
                  htmlType="button"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  // onClick={handleSubmit(onSubmit)}
                >
                  {modaldata.hasOwnProperty('id') ? 'Update' : 'Save'}
                </Button>
              </Col>
            </Row>
          )}
        </Row>
      </Form>
      <div>
        <ModalComponent
          size="md"
          toggleModal={() => toggleModal()}
          modalOpen={historyModal?.isOpen}
          modalHeader={<>Reason</>}
          modalBody={
            <HistoryModal
              historyData={historyData}
              setHistoryData={setHistoryData}
              toggleModal={toggleModal}
              modal={historyModal}
              setModal={setHistoryModal}
              orginalValues={modaldata}
              handleHistoryData={handleHistoryData}
              isModule
            />
          }
        />
      </div>
      <div>
        <ModalComponent
          size="md"
          toggleModal={() => toggleNewModal()}
          modalOpen={newModal?.isOpen}
          modalHeader={<>Edit Field</>}
          modalBody={
            <EditFieldModal
              historyData={historyData}
              setHistoryData={setHistoryData}
              toggleModal={toggleNewModal}
              modal={newModal}
              setModal={setNewModal}
              orginalValues={modaldata}
              handleHistoryData={handleHistoryData}
              isModule
              arrayData={arrayData}
              setArrayData={setArrayData}
              hours={hours}
            />
          }
        />
      </div>
    </div>
  );
};

export default AddModuleComponent;
