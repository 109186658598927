import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import style from '../../../Styles/SampleModal.module.css';
import {
  toastError,
  toastInfo,
  toastSuccess,
} from '../../Toast/ToastComponent';
import AttendanceReport from '../../../Api/attendanceReport';

const ApproveMonthTimesheet = ({
  toggleModal,
  getLogsList,
  filterValue,
  getAttendanceReport,
  userData,
  statusData,
}) => {
  const handleApprove = async () => {
    try {
      let body = {
        month: filterValue?.month,
        year: filterValue?.year,
        user_id: userData?.employee_id,
        isFirstApprover: userData?.isFirstApprover,
        isSecondApprover: userData?.isSecondApprover,
        userRoleID: userData?.userRoleID,
        id: userData?.id,
      };
      const response = await AttendanceReport.approveMonth(body);
      if (response?.data?.success) {
        await getLogsList();
        await getAttendanceReport();
        toastSuccess(response?.data?.message);
        toggleModal();
      } else {
        await getLogsList();
        await getAttendanceReport();
        toastInfo(response?.data?.message);
        toggleModal();
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  const [showData, setShowData] = useState({});

  useEffect(() => {
    const mergeStatsData = () => {
      const monthReport = statusData?.monthReport || [];
      let obj = {};
      for (const data of monthReport) {
        obj[data['label']] = data['value'];
      }
      setShowData({ ...obj, ...statusData?.timesheet_details });
    };
    mergeStatsData();
  }, [statusData]);

  return (
    <div>
      <div className="mt-2 fs-6">
        Are you sure you want to approve this month's timesheet entries?
      </div>
      <div>
        <div className={`${style.statData}`}>
          <div>
            <span>Approved</span>
            <span>-</span>
            <span className="text-success">{showData?.approved || 0}</span>
          </div>
          <div>
            <span>Missed</span>
            <span>-</span>
            <span className="text-muted">{showData['Missed Days:'] || 0}</span>
          </div>
          <div>
            <span>Locked</span>
            <span>-</span>
            <span className="text-warning">
              {showData['Locked Count:'] || 0}
            </span>
          </div>
          <div>
            <span>Pending</span>
            <span>-</span>
            <span className="text-primary">{showData?.pending || 0}</span>
          </div>
          <div>
            <span>Rejected</span>
            <span>-</span>
            <span className="text-danger">{showData?.rejected || 0}</span>
          </div>
        </div>
      </div>
      <div className="antdBtn mt-4 text-center">
        <Button type="default" htmlType="button" onClick={toggleModal}>
          Cancel
        </Button>
        <Button onClick={handleApprove} type="primary" htmlType="submit">
          Approve
        </Button>
      </div>
    </div>
  );
};

export default ApproveMonthTimesheet;
