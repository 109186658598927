import React, { useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { Col, Form, Row } from 'reactstrap';
import TmButton from '../ReusableComponents/TmButton';
import { useNavigate } from 'react-router';
import { Select, Input, Checkbox } from 'antd';
import MasterRoleTable from './MasterRoleTable';
import { MasterRoleApi } from '../../Api/MasterRoleApi';
import { toastError } from '../Toast/ToastComponent';
const { Search } = Input;

const columns = [
  { field: 'checkbox', headerName: <Checkbox /> },
  { field: 'employee_Code', headerName: 'EMPLOYEE CODE' },
  { field: 'employee_name', headerName: 'EMPLOYEE NAME' },
  { field: 'date_of_joining', headerName: 'DATE OF JOINING' },
  {
    field: 'designation_name',
    headerName: 'DESIGNATION',
  },
  { field: 'department_name', headerName: 'DEPARTMENT' },
  { field: 'action', headerName: 'ACTION' },
];

const MasterRoleComponent = () => {
  const [masterRole, setMasterRole] = useState([]);

  const navigate = useNavigate();
  let res = [];
  const addMasterRole = () => {
    navigate('/add_master_role');
  };

  const getMasterRoleList = async () => {
    try {
      let response = await MasterRoleApi.getMasterRole();
      if (response?.data?.success) {
        res = response.data.result;
        setMasterRole(res);
      } else {
        toastError(response?.data?.message);
        return;
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  useEffect(() => {
    getMasterRoleList();
  }, []);

  return (
    <div>
      <div className="row mx-0 mb-2">
        <div className="col-md-4 px-0">
          <h5 className="text-nowrap">Master Role</h5>
        </div>
        <div className="col-md-8 pe-0">
          <div className="row justify-content-end align-items-center">
            <div className="col-md-4 pe-0">
              <Search
                placeholder="Search Projects"
                size="medium"
                allowClear
                style={{ width: '100%' }}
              />
            </div>
            <div className="col-auto">
              <TmButton
                label="Add"
                btnStyle="tm_button1"
                icon={<MdAdd size={20} />}
                onClick={addMasterRole}
              />
            </div>
          </div>
        </div>
      </div>
      <Row>
        <Col md={12}>
          <div>
            <Form>
              <div className="bg-white mb-2 p-1 px-2 border">
                <Row className="m-0 align-items-center">
                  <Col md="auto" className="pe-0">
                    <b>Filter</b>
                  </Col>
                  <Col md={2} className="pe-0">
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      placeholder="Designation"
                      optionFilterProp="children"
                    />
                  </Col>
                  <Col md={2} className="pe-0">
                    <Select
                      style={{ width: '100%' }}
                      showSearch
                      placeholder="Department"
                      optionFilterProp="children"
                    />
                  </Col>
                  <Col md="auto" className="pe-0 ms-auto">
                    <div>
                      <TmButton
                        label="clear"
                        btnStyle="tm_button3 ms-md-2"
                        icon=""
                      />
                      <TmButton
                        label="Apply Filter"
                        btnStyle="tm_button3 ms-md-2"
                        icon={<MdAdd size={25} />}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
            {masterRole.length > 0 && (
              <MasterRoleTable columns={columns} rows={masterRole} />
            )}
          </div>
        </Col>
        <Col></Col>
      </Row>
    </div>
  );
};

export default MasterRoleComponent;
