import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Switch } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import { Label } from 'reactstrap';
import { useAuth } from '../../Context/AuthContext';
import { toastSuccess, toastError, toastWarn } from '../Toast/ToastComponent';
import style from '../../Styles/Department.module.css';
import Designation from '../../Api/Designation';

const AddDesignation = ({
  modalHeader,
  setModal,
  modal,

  modaldata,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  //  const [isModalOpen, setModalOpen] = useState(false);
  //  const toggle = () => {
  //    setModalOpen(!isModalOpen);
  //  };
  const { authState } = useAuth();

  const onSubmit = async (data) => {
    try {
      let newData = { ...data, created_By_ID: authState.id };
      if (modaldata.id) {
        newData.id = modaldata.id;
        newData.modified_By_ID = authState.id;
        delete newData['created_By_ID'];
      }

      const response = newData.hasOwnProperty('created_By_ID')
        ? await Designation.create(newData)
        : await Designation.update(newData);

      if (response.data.success) {
        toastSuccess(response.data.message);
        reset();
        setModal(!modal);
      } else {
        toastWarn(response.data.message);
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  useEffect(() => {
    if (modaldata) {
      let defaultValues = {};
      defaultValues.designation_name = modaldata.title;
      defaultValues.status = modaldata.status;
      reset({ ...defaultValues });
    }
  }, []);

  return (
    <>
      <Form onFinish={handleSubmit(onSubmit)} className="h-100">
        <div className="d-flex flex-column justify-content-between h-100">
          <div>
            <Form.Item className={`${style}`}>
              <Label>
                Designation Name
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </Label>
              <Controller
                name="designation_name"
                control={control}
                defaultValue=""
                rules={{
                  required: 'This field is required',
                  pattern: {
                    value:
                      /^(?!.*\s{2,})(?!.*^\s)(?!.*\s$)[A-Za-z0-9!@#$%^&*()_+={}\[\]|\\:;'",.<>?/\-`~\s]+$/,
                    message: 'Extra spaces are not allowed',
                  },
                }}
                render={({ field }) => (
                  <Input
                    disabled={
                      modalHeader?.props?.children == 'View Designation'
                    }
                    {...field}
                    placeholder="Enter Designation Name"
                  />
                )}
              />
              {errors?.designation_name && (
                <p className="text-danger mt-1">
                  {errors?.designation_name?.message}
                </p>
              )}
            </Form.Item>

            <Form.Item className={`${style}`}>
              <div>
                <div>
                  <Controller
                    name="status"
                    control={control}
                    defaultValue={true}
                    render={({ field }) => (
                      <Switch
                        disabled={
                          modalHeader?.props?.children == 'View Designation'
                        }
                        {...field}
                        name="status"
                      />
                    )}
                  />
                  <span className="ps-2">Active</span>
                </div>
              </div>
            </Form.Item>
          </div>
          {modalHeader?.props?.children !== 'View Designation' && (
            <div className="d-flex justify-content-center antdBtn">
              <Button
                type="default"
                className={style['btn-space']}
                htmlType="button"
                onClick={() => setModal(!modal)}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                // onClick={handleSubmit(onSubmit)}
              >
                Save
              </Button>
            </div>
          )}
        </div>
      </Form>
    </>
  );
};
export default AddDesignation;
