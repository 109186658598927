import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Switch, Select } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import style from '../../Styles/Department.module.css';
import { Label } from 'reactstrap';
import Department from '../../Api/Department';
import { useAuth } from '../../Context/AuthContext';
import {
  toastSuccess,
  toastWarn,
  toastError,
  toastInfo,
} from '../Toast/ToastComponent';
import UserApi from '../../Api/UserApi';

const { Option } = Select;

const AddDepartment = ({
  modaldata,
  getDepartmentList,
  setModal,
  modal,
  modalHeader,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { authState } = useAuth();
  const [authoriry, setAuthority] = useState([]);

  const onSubmit = async (data) => {
    try {
      let newData = {
        ...data,
        created_By_ID: authState.id,
      };

      if (modaldata.id) {
        newData.id = modaldata.id;
        newData.modified_By_ID = authState.id;
        delete newData['created_By_ID'];
      }

      const response = !modaldata.hasOwnProperty('title')
        ? await Department.create(newData)
        : await Department.update(newData);
      if (response.data.success) {
        toastSuccess(response.data.message);
        getDepartmentList();
        setModal(!modal);
        reset();
      } else {
        toastWarn(response.data.message);
        getDepartmentList();
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  const getAuthorityList = async () => {
    try {
      const response = await UserApi.dropDownUsers();
      if (response?.data?.success) {
        setAuthority(response?.data?.result);
      } else {
        toastInfo(response?.data?.message);
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  useEffect(() => {
    getAuthorityList();
    if (modaldata) {
      let defaultValues = {};
      defaultValues.department_name = modaldata.title;
      defaultValues.status = modaldata.status;
      defaultValues.department_head = modaldata.department_head;
      reset({ ...defaultValues });
    }
  }, [modaldata]);

  return (
    <>
      <Form onFinish={handleSubmit(onSubmit)} className="h-100">
        <div className="d-flex flex-column justify-content-between h-100">
          <div>
            <Form.Item className={`${style}`}>
              <Label>
                Department Name
                <span style={{ color: 'red', marginLeft: 2 }}>*</span>
              </Label>
              <Controller
                name="department_name"
                control={control}
                defaultValue=""
                rules={{
                  required: 'This field is required',
                  pattern: {
                    value:
                      /^(?!.*\s{2,})(?!.*^\s)(?!.*\s$)[A-Za-z0-9!@#$%^&*()_+={}\[\]|\\:;'",.<>?/\-`~\s]+$/,
                    message: 'Extra spaces are not allowed',
                  },
                }}
                render={({ field }) => (
                  <Input
                    disabled={modalHeader?.props?.children == 'View Department'}
                    {...field}
                    placeholder="Enter Department Name"
                  />
                )}
              />
              {errors?.department_name && (
                <p className="text-danger my-1 ">
                  {errors?.department_name?.message}
                </p>
              )}
            </Form.Item>

            <Form.Item className={`${style}`}>
              <Label>Department Head</Label>
              <Controller
                name="department_head"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    mode="multiple"
                    placeholder="Select"
                    showSearch
                    disabled={modalHeader?.props?.children == 'View Department'}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {authoriry?.map((element) => (
                      <Option key={element.id} value={element.id}>
                        {element.value}
                      </Option>
                    ))}
                  </Select>
                )}
              />
            </Form.Item>

            <Form.Item className={`${style}`}>
              <div>
                <div>
                  <Controller
                    name="status"
                    control={control}
                    defaultValue={true}
                    render={({ field }) => (
                      <Switch
                        disabled={
                          modalHeader?.props?.children == 'View Department'
                        }
                        {...field}
                        name="status"
                      />
                    )}
                  />
                  <span className="ps-2">Active</span>
                </div>
              </div>
            </Form.Item>
          </div>
          {modalHeader?.props?.children !== 'View Department' && (
            <div className="text-center antdBtn">
              <Button
                onClick={() => setModal(!modal)}
                type="default"
                size="medium"
                htmlType="button"
              >
                Cancel
              </Button>
              <Button type="primary" size="medium" htmlType="submit">
                {modaldata.hasOwnProperty('title') ? 'Update' : 'Save'}
              </Button>
            </div>
          )}
        </div>
      </Form>
    </>
  );
};

export default AddDepartment;
