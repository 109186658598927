import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Form, Input, Button, DatePicker, InputNumber } from 'antd';
import { Row, Col } from 'reactstrap';
import Mandatory from '../ReusableComponents/Mandatory';
import dayjs from 'dayjs';
import { DisplayDateFormat, HISTORY_INPUTS } from '../../constant/constant';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../Context/AuthContext';
import { toastInfo } from '../Toast/ToastComponent';

const EditFieldModal = ({
  toggleModal,
  modal,
  setHistoryData,
  historyData,
  setModal,
  orginalValues,
  handleHistoryData,
  isModule,
  setArrayData,
  arrayData,
  hours,
}) => {
  const { authState } = useAuth();
  const [form] = Form.useForm();

  const { edit_id, view_type } = useParams();

  const onSubmit = async (data) => {
    if (isModule && modal?.fields?.fieldType == 'estimated_hours') {
      if (
        data?.estimated_hours + orginalValues?.estimated_hours >
        orginalValues?.estimated_hours + hours?.balanceHours
      ) {
        toastInfo('Balance hours is exceeding');
        return;
      }
    }

    let newArray = [...arrayData?.value];

    newArray[newArray?.length - 1][modal?.fields?.fieldType].value =
      data[modal?.fields?.fieldType];
    if (HISTORY_INPUTS.includes(modal?.fields?.fieldType)) {
      newArray[newArray?.length - 1][modal?.fields?.fieldType].addedValue =
        orginalValues[modal?.fields?.fieldType] +
        data[modal?.fields?.fieldType];
    }
    newArray[newArray?.length - 1][modal?.fields?.fieldType].reason =
      data.reason;
    setArrayData((prev) => ({
      ...prev,
      value: newArray,
    }));

    let obj = {
      reason: data?.reason,
      project_id: isModule ? orginalValues?.project_id : atob(edit_id),
      ...(isModule && { module_id: orginalValues?.id }),
      fieldType: modal?.fields?.fieldType,
      orginalValue: orginalValues[modal?.fields?.fieldType],
      updatedBy: Number(authState?.id),
      updatedValue: HISTORY_INPUTS.includes(modal?.fields?.fieldType)
        ? orginalValues[modal?.fields?.fieldType] +
          data[modal?.fields?.fieldType]
        : data[modal?.fields?.fieldType],
    };

    handleHistoryData(modal?.fields?.fieldType, obj);

    setModal({ isOpen: false, field: undefined });
  };

  const customizeRequiredMark = (label, { required }) => (
    <>
      {label}
      {required && <Mandatory />}
    </>
  );

  useEffect(() => {
    form.setFieldValue(modal?.fields?.fieldType, modal?.fields?.value);
    form.setFieldValue('reason', modal?.fields?.reason);
  }, [modal]);

  return (
    <>
      <div>
        <Form
          layout="vertical"
          form={form}
          onFinish={onSubmit}
          requiredMark={customizeRequiredMark}
        >
          <Row>
            <Col>
              <Form.Item
                name={modal?.fields?.fieldType}
                label={`${modal?.fields?.label} ${
                  HISTORY_INPUTS.includes(modal?.fields?.fieldType)
                    ? '(Values will be added to existing value)'
                    : ''
                } `}
                rules={[{ required: true, message: 'This field is required' }]}
              >
                {modal?.fields?.isDateField ? (
                  <DatePicker
                    minDate={dayjs(modal?.fields?.minDate) || dayjs()}
                    value={modal?.fields?.value}
                    placeholder={modal?.fields?.label}
                    style={{ width: '100%' }}
                    format={DisplayDateFormat}
                  />
                ) : (
                  <InputNumber
                    value={modal?.fields?.value}
                    style={{ width: '100%' }}
                    type="number"
                    maxLength={200}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="reason"
                label={`Reason`}
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Maximum 200 characters"
                  maxLength={200}
                />
              </Form.Item>
              <div className="text-center antdBtn">
                <Button
                  onClick={() => toggleModal()}
                  type="default"
                  htmlType="button"
                >
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default EditFieldModal;
