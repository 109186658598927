import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  TimePicker,
  InputNumber,
} from 'antd';
import enUS from 'antd/es/locale/en_US';
import { useForm, Controller } from 'react-hook-form';
import TextArea from 'antd/es/input/TextArea';

import { Label, Row, Col } from 'reactstrap';
import dayjs from 'dayjs';
import {
  DateFormat,
  DisplayDateFormat,
  LEAVETYPE,
} from '../../constant/constant';
import Mandatory from '../ReusableComponents/Mandatory';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import { LeaveManageApi } from '../../Api/LeaveManageApi';
import {
  toastError,
  toastInfo,
  toastSuccess,
  toastWarn,
} from '../Toast/ToastComponent';
import { useAuth } from '../../Context/AuthContext';
import { Leave } from '../../Api/LeaveApi';
import { getDatesBetween } from '../../helpers/datesBetween';
const ApplyLeaveComponent = ({
  modalData,
  getLeaveManageList,
  toggleModal,
}) => {
  const [form] = Form.useForm();

  const { authState } = useAuth();

  const [holidayList, setHolidayList] = useState([]);

  const startDate = Form.useWatch('startDate', form);

  const endDate = Form.useWatch('endDate', form);

  const { Option } = Select;

  const onSubmit = async (data) => {
    try {
      if (authState.id == 1 || authState.id == 2) {
        toastInfo('Application Admin cannot create leave requests.');
        return;
      }

      let newData = { ...data, user_id: authState.id };

      if (modalData.hasOwnProperty('id')) {
        newData.id = modalData?.id;
      }
      const response = modalData.hasOwnProperty('id')
        ? await LeaveManageApi.edit(newData)
        : await LeaveManageApi.create(newData);

      if (response.data.success) {
        form.resetFields();
        toastSuccess(response.data.message);
        getLeaveManageList();
        toggleModal();
      } else {
        toastInfo(response.data.message);
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  const getHolidayList = async () => {
    try {
      const response = await Leave.getHolidayList();
      if (response?.data?.success) {
        setHolidayList(response?.data?.result);
      } else {
        toastInfo(response?.data?.message);
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  useEffect(() => {
    if (modalData.hasOwnProperty('id')) {
      modalData.startDate = dayjs(modalData.startDate);
      modalData.endDate = dayjs(modalData.endDate);

      form.setFieldsValue(modalData);
    }
    getHolidayList();
  }, [modalData]);

  const customizeRequiredMark = (label, { required }) => (
    <>
      {label}
      {required && <Mandatory />}
    </>
  );

  const calculateHr = () => {
    const { endDate, startDate } = form.getFieldsValue();

    if (startDate && endDate) {
      const millisecondsInADay = 1000 * 60 * 60 * 24;

      const dates = getDatesBetween(startDate, endDate);

      let dayCount = [];
      let holidayArray = [];

      for (const holiday of holidayList) {
        holidayArray.push(holiday?.date_of_holiday);
      }

      for (const date of dates) {
        if (
          !['"Saturday"', '"Sunday"'].includes(dayjs(date).format('"dddd"'))
        ) {
          const indexValue = dayCount?.findIndex((value) => value == date);
          if (indexValue == -1) {
            if (!holidayArray.includes(date)) {
              dayCount.push(date);
            }
          }
        }
      }

      const daysDifference =
        Math.ceil(
          (endDate.valueOf() - startDate.valueOf()) / millisecondsInADay,
        ) + 1;

      form.setFieldsValue({ daycount: dayCount.length });
    } else {
      form.setFieldsValue({ daycount: null });
    }
  };

  const disableWeekends = (current) => {
    return current && (current.day() === 0 || current.day() === 6);
  };

  return (
    <>
      <div>
        <Form
          layout="vertical"
          form={form}
          onFinish={onSubmit}
          requiredMark={customizeRequiredMark}
        >
          <Row>
            <Col>
              <div className="row justify-content-start">
                <div className="col-5">
                  <Form.Item
                    className="w-100"
                    name="startDate"
                    label="Start Date"
                    rules={[
                      { required: true, message: 'This field is required' },
                    ]}
                  >
                    <DatePicker
                      className="w-100"
                      // format={DateFormat}
                      format={'DD-MM-YYYY'}
                      maxDate={endDate}
                      placeholder="DD-MM-YYYY"
                      onChange={calculateHr}
                      //disabledDate={disableWeekends}
                    />
                  </Form.Item>
                </div>
                <div className="col-5">
                  <Form.Item
                    className="w-100"
                    name="endDate"
                    rules={[
                      { required: true, message: 'This field is required' },
                    ]}
                    label="End Date"
                  >
                    <DatePicker
                      className="w-100"
                      // format={DateFormat}
                      format={DisplayDateFormat}
                      placeholder="DD-MM-YYYY"
                      minDate={startDate}
                      // onChange={() => calculateHr("reset")}
                      // disabledDate={disableWeekends}
                      onChange={calculateHr}
                    />
                  </Form.Item>
                </div>
                <div className="col-2">
                  <Form.Item name="daycount" label={<>&nbsp;</>}>
                    <Input readOnly type="number" />
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                name="type"
                label="Type"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Select
                  options={LEAVETYPE}
                  type="select"
                  placeholder="Select Type"
                  showSearch
                ></Select>
              </Form.Item>
              <Form.Item
                name="reason"
                label="Reason"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input.TextArea rows={4} placeholder="Write here" />
              </Form.Item>
              {/* <Form.Item
                name="reporting"
                label="Reporting Authority"
                rules={[{ required: true, message: "This field is required" }]}>
                <Select
                  type="select"
                  placeholder="Select Reporting Authority"
                  showSearch></Select>
              </Form.Item> */}
              <div className="text-center antdBtn">
                <Button
                  onClick={() => toggleModal()}
                  type="default"
                  htmlType="button"
                >
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default ApplyLeaveComponent;
