import { Table } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import ShowStatus from '../ReusableComponents/ShowStatus';
import { IoEyeOutline } from 'react-icons/io5';
import { FiEdit } from 'react-icons/fi';
import { TiPowerOutline } from 'react-icons/ti';
import {
  MdDeleteOutline,
  MdDoNotDisturbOnTotalSilence,
  MdHighlightAlt,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown } from 'antd';
import style from '../../Styles/Project.module.css';
import TmPagination from '../../Components/ReusableComponents/Pagination';
import { NORECORD, PaginationRange } from '../../constant/constant';
import ListMembers from '../ReusableComponents/ListMembers';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useAuth } from '../../Context/AuthContext';
import { IoMdLogIn } from 'react-icons/io';

const ProjectTable = (props) => {
  const { rows, columns, buttonPermission, view_type, handleClicks, table } =
    props;
  const navigate = useNavigate();
  const [pagination, setPagination] = useState([]);
  const determineView = view_type == true ? 'card' : ' table';
  useEffect(() => {
    PaginationRange.setPageSize(10);
    handlePagination(1, rows);
  }, [rows]);
  const { authState } = useAuth();
  const handlePagination = async (data, current = []) => {
    let endRange = data * Number(PaginationRange.endRange) - 1;
    let startRange =
      data * Number(PaginationRange.startRange) -
      Number(PaginationRange.initalStart);
    const check = current.length ? current : rows;
    setPagination(
      check.filter((_, index) => index <= endRange && index >= startRange),
    );
  };

  const onShowSizeChange = (current, pageSize) => {
    PaginationRange.setPageSize(pageSize);
    handlePagination(current, rows);
  };
  const handleDisable = (value, status) => {
    handleClicks(value, status);
  };
  const handleEdit = (id) => {
    navigate(`/add_Project/${btoa(determineView)}/${btoa(id)}`);
  };
  const handleClick = (id) => {
    // navigate(`/View_Project/${btoa(id)}`);
    navigate(
      `/View_Project/${btoa(determineView)}/${btoa(id)}/${btoa(
        buttonPermission.edit,
      )}`,
    );
  };

  const showActionMenu = (item) => {
    let arrayData = [
      {
        key: item?.id,
        label: (
          <div onClick={() => handleClick(item.id)}>
            <button
              className="btn p-0  m-0 icon-hover"
              title="View"
              type="button"
            >
              <IoEyeOutline size={18} /> View
            </button>
          </div>
        ),
      },
    ];

    let checkArray = [
      {
        condition: !item?.deleteStatus && buttonPermission.edit,
        onClick: (e) => {
          e.stopPropagation();
          handleEdit(item.id);
        },
        icon: (
          <span>
            <FiEdit size={16} />
          </span>
        ),
        label: 'Edit',
      },
      {
        condition: !item?.deleteStatus && buttonPermission.delete,
        onClick: (e) => {
          e.stopPropagation();
          handleDisable(item.id, item.deleteStatus);
        },
        icon: <MdDoNotDisturbOnTotalSilence size={18} />,
        label: 'Deactivate',
      },
      {
        condition: item?.deleteStatus && buttonPermission.delete,
        onClick: (e) => {
          e.stopPropagation();
          handleDisable(item.id, item.deleteStatus);
        },
        icon: <TiPowerOutline size={20} />,
        label: 'Activate',
      },
      {
        condition: true,
        onClick: (id) => {
          navigate(`/project_History/${btoa(item.id)}`);
        },
        icon: <MdHighlightAlt size={20} />,
        label: 'View History',
      },
    ];

    for (const [index, element] of checkArray.entries()) {
      if (element?.condition) {
        let obj = {
          key: index + 1,
          label: (
            <div onClick={element?.onClick}>
              <button
                className="btn p-0 m-0 icon-hover"
                type="button"
                title={element?.label}
              >
                {element?.icon} {element?.label}
              </button>
            </div>
          ),
        };
        arrayData.push(obj);
      }
    }

    return arrayData || [];
  };

  return (
    <div>
      <Table responsive className="mt-2">
        <thead>
          <tr>
            {columns.map((item, index) => (
              <th key={index}>{item.headerName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {pagination.length <= 0 && (
            <tr>
              <td colSpan={100} className="text-center">
                {NORECORD}
              </td>
            </tr>
          )}
          {pagination.length > 0 &&
            pagination?.map((item, idx) => (
              <tr
                style={{ cursor: 'pointer' }}
                onClick={() => handleClick(item.id)}
                className={`hoverTr ${item?.deleteStatus ? style.disable : ''}`}
                key={idx}
              >
                <td>{[item.serialNo || '--']} </td>
                <td title={item.project_name}>
                  {' '}
                  {[item.project_name || '--']}
                </td>
                <td title={item.department_name}>
                  {' '}
                  {[item.department_name || '--']}
                </td>
                <td>
                  {' '}
                  {item.estimatedHours ? `${item.estimatedHours} hrs` : '--'}
                </td>
                <td> {item.totalHours ? `${item.totalHours} hrs` : '--'}</td>
                <td> {[item.module_count || '--']}</td>

                <td>
                  {' '}
                  {item.totalEstimatedHours
                    ? `${item.totalEstimatedHours} hrs`
                    : '--'}
                </td>
                <td className="cursor-pointer align-middle moduleTable">
                  <ListMembers members={item?.members_name || []} />
                </td>
                {/* <td> {[item.totalBillableHours]} hrs</td> */}
                <td> {item.created_By_name || '--'}</td>
                <td> {item.modified_By_Name || '--'}</td>
                {(buttonPermission == undefined ||
                  buttonPermission?.edit ||
                  buttonPermission?.delete ||
                  buttonPermission?.view) && (
                  <td
                    className="text-center "
                    onClick={(e) => e.stopPropagation()}
                  >
                    {showActionMenu(item).length > 0 && (
                      <Dropdown
                        menu={{
                          items: showActionMenu(item) || [],
                        }}
                        placement="bottomRight"
                        arrow
                      >
                        <Button
                          type="text"
                          htmlType="button"
                          icon={<BsThreeDotsVertical />}
                        ></Button>
                      </Dropdown>
                    )}
                  </td>
                )}
                {/* <td>

                  <span>
                    <button
                      className="btn p-0  m-0 icon-hover"
                      title="View"
                      type="button"
                      onClick={() => handleClick(item.id)}
                    >
                      <IoEyeOutline size={18} />
                    </button>
                  </span>
                  <span>
                    {!item?.deleteStatus && buttonPermission.edit && (
                      <button
                        className="btn p-0 m-0 icon-hover"
                        type="button"
                        title="Edit"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEdit(item.id);
                        }}
                      >
                        <FiEdit size={16} />
                      </button>
                    )}
                  </span>
                  <span>
                    {' '}
                    {!item?.deleteStatus && buttonPermission.delete && (
                      <button
                        className="btn p-0 m-0 icon-hover "
                        type="button"
                        title="Deactivate"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDisable(item.id, item.deleteStatus);
                        }}
                      >
                        <MdDoNotDisturbOnTotalSilence size={18} />
                      </button>
                    )}
                  </span>
                  <span>
                    {' '}
                    {item?.deleteStatus && buttonPermission.delete && (
                      <button
                        className="btn p-0 m-0 icon-hover "
                        type="button"
                        title="Activate"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDisable(item.id, item.deleteStatus);
                        }}
                      >
                        <TiPowerOutline size={20} />
                      </button>
                    )}
                  </span>
                </td> */}
              </tr>
            ))}
        </tbody>
      </Table>
      <div className="pt-md-0 mt-1 pt-4">
        <TmPagination
          onShowSizeChange={onShowSizeChange}
          total={rows.length}
          handlePagination={handlePagination}
        />
      </div>
    </div>
  );
};

export default ProjectTable;
