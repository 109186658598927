import React, { useEffect, useRef, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router';
import { Button, Input } from 'antd';
import { ProjectApi } from '../../Api/ProjectApi';
import {
  MODAL_HEIGHT,
  MODULE_STATUS,
  ACTIVE_STATUS,
} from '../../constant/constant';
import { toastError, toastSuccess, toastWarn } from '../Toast/ToastComponent';

import ModalComponent from '../ReusableComponents/ModalComponent';

import TmFilter from '../ReusableComponents/TmFilter';
import Module from '../../Api/Module';
import Loader from '../ReusableComponents/Loader';
import { projectCardDelete } from '../ReusableComponents/DeletePopup';
import { getModules } from '../../store/reducers/actionPermission';
import { useSelector } from 'react-redux';
import AntdModalComp from '../ReusableComponents/AntdModalComp';
import { useAuth } from '../../Context/AuthContext';
import ModuleTableComponent from '../Module/ModuleTableComponents';
import AddModuleComponent from '../Module/AddModuleComponent';
const { Search } = Input;

const columns = [
  { field: 'serialNo', headerName: 'S.NO', className: 'col-auto' },
  { field: 'module_name', headerName: 'MODULE', className: 'col-2' },
  { field: 'project_name', headerName: 'PROJECT', className: 'col-2' },
  {
    field: 'totalHours',
    headerName: 'HOURS TAKEN',
    className: 'col-2',
  },
  {
    field: 'estimated_hours',
    headerName: 'ESTIMATE HOURS',
    className: 'col-2',
  },
  {
    field: 'access_members',
    headerName: 'ASSIGNED MEMBERS',
    className: 'col-3',
  },
  { field: 'createdAt', headerName: 'CREATED DATE', className: 'col-2' },
  { field: 'created_By_name', headerName: 'CREATED BY', className: 'col-2' },
  { field: 'modified_By_Name', headerName: 'MODIFIED BY', className: 'col-2' },
  { field: 'status', headerName: 'STATUS', className: 'col-auto' },
  { field: 'action', headerName: 'ACTION', className: 'col-1' },
];

let filterValue = {
  module_status: '',
  project_id: '',
  activeStatus: true,
  overallsearch: '',
};

let modalHeader;
const DraftModuleComp = () => {
  const { authState } = useAuth();
  const debounceTimeoutRef = useRef(null);

  const moduleData = useSelector(getModules);
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({});
  const [filterFeeds, setFilterFeeds] = useState({
    project: [],
  });

  useEffect(() => {
    getData();
    getDropDown();
    if (moduleData) {
      handleButtonPermission();
    }
    return () => {
      filterValue = { module_status: '', project_id: '', activeStatus: true };
    };
  }, [moduleData]);

  const [buttonPermission, setButtonPermissions] = useState({
    add: undefined,
    edit: undefined,
    delete: undefined,
  });

  const handleButtonPermission = () => {
    if (!moduleData?.checked) {
      navigate('/');
    }
    for (const element of moduleData?.actions) {
      const keyName = element?.statename?.toLowerCase();
      setButtonPermissions((prevState) => ({
        ...prevState,
        [keyName]: element?.checked,
      }));
    }
  };

  const navigate = useNavigate();

  let filterArray = [
    {
      options: filterFeeds.project,
      optionFilterProp: 'label',
      placeholder: 'Project',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: null,
      controlName: 'project_id',
      allowClear: true,
      input: 'select',
    },
    {
      options: MODULE_STATUS,
      optionFilterProp: 'label',
      placeholder: 'Module Status',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: null,
      controlName: 'module_status',
      allowClear: true,
      input: 'select',
    },
    {
      options: ACTIVE_STATUS,
      optionFilterProp: 'label',
      placeholder: 'Status',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: true,
      controlName: 'activeStatus',
      allowClear: true,
      input: 'select',
    },
    {
      order: 1,
      col: 'auto',
      className: 'ms-auto pe-0',
      input: 'button',
      type: 'dashed',
      htmlType: 'button',
      text: 'Reset',
    },
  ];

  const handleFilterChange = (obj, type) => {
    // filterValue = { ...filterValue, [keys]: e };
    // filterValue = obj || {};
    filterValue = { ...filterValue, ...obj } || {};
    getData();
  };

  const toggleModal = async (type, value, e) => {
    if (e) {
      e?.stopPropagation();
    }
    if (value && (type === 'View' || type === 'Edit')) {
      try {
        const response = await Module.getModule(value?.id);
        if (response?.data?.success) {
          const moduleData = response.data.result[0];
          setModalData(moduleData);
        } else {
          toastError(response?.data?.message);
        }
      } catch (error) {
        toastError(error.message);
      }
    } else if (type === 'Add') {
      setModalData({});
    }
    modalHeader = <span className="text-nowrap">{`${type} Draft Module`}</span>;
    setModal(!modal);
  };

  const getData = async () => {
    try {
      let obj = {
        ...filterValue,
        user_id: authState?.id,
        userRoleID: authState?.userRoleID,
        isDraft: true,
      };
      const response = await Module.get(obj);
      if (response?.data?.success) {
        setData(response?.data?.result);
        setLoading(true);
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error?.message);
    }
  };

  const getDropDown = async () => {
    try {
      const response = await ProjectApi.dropDown({
        id: authState.id,
        userRoleID: authState?.userRoleID,
      });
      if (response?.data?.success) {
        setFilterFeeds((pre) => ({ ...pre, project: response.data?.result }));
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      toastError(error.message);
    }
  };
  // const handleClick = (id, type, e) => {

  //   if (type === 'edit') {
  //     navigate(`/add_user/${id}`);
  //   } else if (type === 'View') {
  //     navigate(`/user/view/${btoa(id)}`);
  //   } else if (type === 'delete') {
  //     callDelete(id);
  //     // navigate(`/user/view/${btoa(id)}`);
  //   }
  // };
  const handleClicks = async (id, status) => {
    await projectCardDelete(async (canDelete) => {
      if (canDelete) {
        try {
          let reqdata = {
            id: id,
            user_id: authState.id,
          };
          let response = await Module.delete(reqdata);
          if (response.data.success) {
            toastSuccess(response.data.message);
            getData();
          } else {
            toastWarn(response.data.message);
          }
        } catch (error) {
          toastError(error.message);
        }
      }
    }, status);
  };
  const handleSearch = (e) => {
    let value = e.target.value.trim();

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      handleFilterChange({ ...filterValue, overallsearch: value });
    }, 400);
  };
  return (
    <div>
      <div className="row mx-0 mb-2 ">
        <div className="col-md-4 px-0">
          <h5 className="text-nowrap">Draft Module</h5>
        </div>
        <div className="col-md-3 px-0 ps-md-5" style={{ marginLeft: 'auto' }}>
          <Search allowClear onChange={handleSearch} placeholder="Search " />
          <span>
            <AntdModalComp
              width="60%"
              toggleModal={() => toggleModal('Add', null)}
              modalOpen={modal}
              modalHeader={modalHeader}
              height={MODAL_HEIGHT.auto}
              modalBody={
                <AddModuleComponent
                  modaldata={modalData}
                  getData={getData}
                  setModal={setModal}
                  modal={modal}
                  modalHeader={modalHeader}
                />
              }
            />
          </span>
        </div>
      </div>

      {loading && (
        <div>
          <Row>
            <Col>
              <div>
                <TmFilter
                  filterArray={filterArray}
                  handleFilterChange={handleFilterChange}
                />
              </div>
            </Col>
            <Col md={12}>
              <div>
                <ModuleTableComponent
                  columns={columns}
                  rows={data}
                  toggleModal={toggleModal}
                  handleClicks={handleClicks}
                  modaldata={modalData}
                  buttonPermission={buttonPermission}
                />
              </div>
            </Col>
            <Col></Col>
          </Row>
        </div>
      )}
      {!loading && <Loader />}
    </div>
  );
};

export default DraftModuleComp;
