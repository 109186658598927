import { Button, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  toastError,
  toastInfo,
  toastSuccess,
} from '../../Toast/ToastComponent';
import { TimeSheetApi } from '../../../Api/TimeSheetApi';
import { ReactReduxContext } from 'react-redux';

const ApproveAllPopUp = ({
  toggleModal,
  getLogsList,
  approveType,
  filterValue,
  userData,
  getAttendanceReport,
}) => {
  const [data, setData] = useState([]);
  const getCheckboxData = () => {
    let dataArray = [
      {
        label: `${approveType} Pending Items`,
        value: true,
        name: 'pendingItems',
      },
      {
        label: `${approveType} ${
          approveType == 'Approve All' ? 'Rejected' : 'Approved'
        } Items`,
        value: false,
        name: 'alterItems',
      },
      {
        label: `${approveType} Permission requests, as well as here`,
        value: false,
        name: 'permissionRequest',
      },
      {
        label: `${approveType} Leave requests, as well as here`,
        value: false,
        name: 'leaveRequest',
      },
      {
        label: `${approveType} Locked Items`,
        value: false,
        name: 'lockItems',
      },
    ];
    setData(dataArray);
  };

  useEffect(() => {
    getCheckboxData();
  }, [approveType]);

  const handleChange = async (event) => {
    const { name } = event.target;
    const prevState = [...data];
    for (const element of prevState) {
      if (element?.name == name) {
        element.value = event.target.checked;
      }
    }
    setData(prevState);
  };

  const handleSubmit = async () => {
    try {
      if (filterValue) {
        filterValue.user_id = userData?.employee_id;
      }

      let timesheetStatus = data
        ?.filter((element) => element?.value)
        .map((element) => element?.name);

      let body = {
        filterValue,
        type: approveType == 'Approve All' ? 'approve' : 'reject',
        isFirstApprover: userData?.isFirstApprover,
        isSecondApprover: userData?.isSecondApprover,
        userRoleID: userData?.userRoleID,
        approver_id: userData?.user_id,
        timesheetStatus,
      };

      const response = await TimeSheetApi.multiApprove(body);

      if (response?.data?.success) {
        await getLogsList();
        await getAttendanceReport();
        toggleModal();
        toastSuccess(response?.data?.message);
      } else {
        await getLogsList();
        toggleModal();
        toastInfo(response?.data?.message);
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  return (
    <div>
      <div className="pt-1 fs-6">
        {' '}
        {(approveType === 'ApproveAll' && (
          <span>Are you sure you want to approve all these timesheets?</span>
        )) ||
          (approveType === 'RejectAll' && (
            <span>Are you sure you want to reject all these timesheets?</span>
          ))}
      </div>

      {data.map((item, idx) => (
        <div className="pt-3 d-flex align-items-center" key={idx}>
          <Checkbox
            disabled={item?.name == 'pendingItems'}
            name={item?.name}
            onChange={handleChange}
            checked={item?.value}
          >
            <span className="m-1"> {item.label}</span>
          </Checkbox>
        </div>
      ))}
      <div className="antdBtn mt-2 text-center">
        <Button type="default" htmlType="button" onClick={toggleModal}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} type="primary" htmlType="submit">
          {approveType == 'Approve All' ? 'Approve' : 'Reject'}
        </Button>
      </div>
    </div>
  );
};

export default ApproveAllPopUp;
