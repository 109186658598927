import React, { useEffect, useState, useRef } from 'react';
import { Col, Row } from 'reactstrap';
import { Button, Modal, Select, Input } from 'antd';

import { MdAdd } from 'react-icons/md';
import { Leave } from '../../Api/LeaveApi';
import { toastWarn, toastError, toastSuccess } from '../Toast/ToastComponent';
import ModalComponent from '../ReusableComponents/ModalComponent';
import { useSelector } from 'react-redux';
import AddLeave from './AddLeave';
import dayjs from 'dayjs';
import { CurrentDate, DateFormat } from '../../constant/constant';
import moment from 'moment';
import TmMasterTable from '../ReusableComponents/TmMasterTable';
import { DeletePopup } from '../ReusableComponents/DeletePopup';
import { PaginationRange } from '../../constant/constant';
import Loader from '../ReusableComponents/Loader';
import { leaveCompHeader } from '../../constant/tableHeader';
import { useNavigate } from 'react-router-dom';
import { YEARS } from '../../constant/constant';
import AntdModalComp from '../ReusableComponents/AntdModalComp';

import { getLeave } from '../../store/reducers/actionPermission';
import TmFilter from '../ReusableComponents/TmFilter';
import { useAuth } from '../../Context/AuthContext';
import { generateYears } from '../../ReusableFn/reusableFn';
import useFilterStateData from '../../customHooks/useFilterStateData';
const columns = [
  { field: 'holiday_name', headerName: 'PUBLIC HOLIDAYS' },
  { field: 'date_of_holiday_table', headerName: 'DATE' },
];
const { Search } = Input;
let modalHeader;
let modalData = {};
const YearsArr = generateYears();

const LeaveComponent = () => {
  const navigate = useNavigate();
  const [leaveList, setLeaveList] = useState([]);
  const { authState } = useAuth();
  const debounceTimeoutRef = useRef(null);
  const { month: filterStateMonth, year: filterStateYear } =
    useFilterStateData();

  const filterArray = [
    {
      options: YearsArr,
      optionFilterProp: 'label',
      placeholder: 'Year',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: filterStateYear,
      controlName: 'year',
      allowClear: true,
      input: 'select',
    },
    {
      order: 1,
      col: 'auto',
      className: 'ms-auto pe-0',
      input: 'button',
      type: 'dashed',
      htmlType: 'button',
      text: 'Reset',
    },
  ];

  const [filterArr, setFilterArr] = useState({
    search: '',
    year: filterStateYear,
    created_by_id: authState.id,
  });

  const [modal, setModal] = useState(false);
  const [toggleView, setToggleView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reqDate, setReqDate] = useState({
    date: moment(CurrentDate()).format(DateFormat),
    day: moment(CurrentDate()).format('dddd'),
  });

  const data = useSelector(getLeave);

  const [buttonPermission, setButtonPermissions] = useState({
    add: undefined,
    edit: undefined,
    delete: undefined,
  });

  const handleToggle = () => {
    setToggleView(!toggleView);
  };

  useEffect(() => {
    getHolidayLists(filterArr);
    if (data) {
      handleButtonPermission();
    }
  }, [modal, data]);

  const handleButtonPermission = () => {
    if (!data?.checked) {
      navigate('/');
    }
    for (const element of data?.actions) {
      const keyName = element?.statename?.toLowerCase();
      setButtonPermissions((prevState) => ({
        ...prevState,
        [keyName]: element?.checked,
      }));
    }
  };

  const toggleModal = (type, value) => {
    if (type == 'Close') {
      setModal(!modal);
      return;
    }

    if (type === 'Add') {
      modalData = {
        type,
        holiday_name: '',
        id: '',
        date_of_holiday: '',
      };
    } else if (value && (type === 'Edit' || type === 'View')) {
      modalData = {
        type,
        holiday_name: value.holiday_name,
        id: value.id,
        date_of_holiday: value.date_of_holiday,
      };
    }
    modalHeader = <span className="text-nowrap">{`${type} Holiday`}</span>;
    setModal(!modal);
  };

  const handleCalendarData = (e) => {
    setReqDate({
      date: moment(dayjs(e).$d).format(DateFormat),
      day: moment(dayjs(e).$d).format('dddd'),
    });
  };

  const getHolidayLists = async (filterArr) => {
    try {
      const response = await Leave.getHolidayList(filterArr);
      let resData = response.data;
      if (resData?.success) {
        setLeaveList(resData.result);
        setLoading(true);
      } else {
        toastWarn(resData.message);
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  const callDelete = async (id) => {
    await DeletePopup(async (canDelete) => {
      if (canDelete) {
        try {
          const response = await Leave.deleteHoliday(id);
          if (response.data.success) {
            toastSuccess(response.data.message);
            getHolidayLists(filterArr);
          } else {
            toastWarn(response.data.message);
          }
        } catch (error) {
          //toastError(error.response.data.message);
          toastError(error);
        }
      }
    });
  };

  const handleFilterChange = (obj) => {
    getHolidayLists({ ...filterArr, ...obj });
    setFilterArr((pre) => ({ ...pre, year: obj.year }));
  };
  const handleSearch = (e) => {
    let value = e.target.value.trim();

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      getHolidayLists({ ...filterArr, search: value });
    }, 400);
    setFilterArr((pre) => ({ ...pre, search: value }));
  };
  return (
    <div>
      <div className="d-md-flex">
        <div>
          {' '}
          <h5 className="text-nowrap">Public Holidays</h5>
        </div>
        <div className="ms-md-auto d-flex ">
          <div className=" me-2">
            {' '}
            <Search
              allowClear
              onChange={handleSearch}
              placeholder="Search "
              value={filterArr.search}
            />
          </div>
          <div>
            <span>
              {buttonPermission?.add && (
                <Button
                  title="Add"
                  type="primary"
                  htmlType="button"
                  icon={<MdAdd size={20} />}
                  onClick={() => toggleModal('Add', null)}
                ></Button>
              )}
            </span>
          </div>
        </div>
      </div>

      <Row>
        <div className="mt-2 mb-0">
          <TmFilter
            filterArray={filterArray}
            handleFilterChange={handleFilterChange}
          />
        </div>
        <Col sm={12}>
          {loading && (
            <div className="">
              <TmMasterTable
                buttonPermission={buttonPermission}
                modaldata={modalData}
                toggleModal={toggleModal}
                rows={leaveList}
                columns={leaveCompHeader}
                custom={['action']}
                handleDelete={callDelete}
              />
            </div>
          )}
          {!loading && <Loader />}
        </Col>
      </Row>
      <span>
        <AntdModalComp
          width="30%"
          toggleModal={() => toggleModal('Close', null)}
          modalOpen={modal}
          modalHeader={modalHeader}
          modalBody={
            <AddLeave
              modalHeader={modalHeader}
              modaldata={modalData}
              getHolidayLists={getHolidayLists}
              setModal={setModal}
              modal={modal}
            />
          }
        />
      </span>
    </div>
  );
};

export default LeaveComponent;
