import React, { useEffect, useRef, useState } from 'react';
import ModalComponent from '../ReusableComponents/ModalComponent';
import AddDepartment from './AddDepartment';
import { MdAdd } from 'react-icons/md';
import TmMasterTable from '../ReusableComponents/TmMasterTable';
import Department from '../../Api/Department';
import { toastError, toastSuccess, toastWarn } from '../Toast/ToastComponent';
import { Button, Input } from 'antd';
import { DeletePopup } from '../ReusableComponents/DeletePopup';
import { getDepartment } from '../../store/reducers/actionPermission';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from '../ReusableComponents/Loader';
import { DepartmentCompHeader } from '../../constant/tableHeader';
import AntdModalComp from '../ReusableComponents/AntdModalComp';
import TmFilter from '../ReusableComponents/TmFilter';
import { ACTIVE_STATUS } from '../../constant/constant';
const { Search } = Input;

let modalHeader;
let modalData = {};

export const DepartmentComponent = () => {
  const navigate = useNavigate();
  const debounceTimeoutRef = useRef(null);
  const [filterArr, setFilterArr] = useState({ search: '', status: true });
  let filterList = [
    {
      options: ACTIVE_STATUS,
      optionFilterProp: 'label',
      placeholder: 'Status',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: filterArr.status,
      controlName: 'status',
      allowClear: true,
      input: 'select',
    },
    {
      order: 1,
      col: 'auto',
      className: 'ms-auto pe-0',
      input: 'button',
      type: 'dashed',
      htmlType: 'button',
      text: 'Reset',
    },
  ];
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [department, setDepartment] = useState([]);

  const data = useSelector(getDepartment);

  const [buttonPermission, setButtonPermissions] = useState({
    add: undefined,
    edit: undefined,
    delete: undefined,
    view: undefined,
  });

  const handleFilterChange = (obj, type) => {
    setFilterArr((pre) => ({ ...pre, ...obj }));
    getDepartmentList({ ...filterArr, ...obj });
  };

  useEffect(() => {
    getDepartmentList(filterArr);
    if (data) {
      handleButtonPermission();
    }
  }, [modal, data]);

  const handleButtonPermission = () => {
    if (!data?.checked) {
      navigate('/');
    }
    for (const element of data?.actions) {
      const keyName = element?.statename?.toLowerCase();
      setButtonPermissions((prevState) => ({
        ...prevState,
        [keyName]: element?.checked,
      }));
    }
  };

  const toggleModal = (type, value, e) => {
    if (type == 'Close') {
      setModal(!modal);
      return;
    }

    if (value && (type === 'View' || type === 'Edit')) {
      modalData = {
        title: value.department_name,
        id: value.id,
        status: value.status,
        department_head: value.department_head,
      };
    } else if (type === 'Add') {
      modalData = {};
    }

    modalHeader = <span className="text-nowrap">{`${type} Department`}</span>;
    setModal(!modal);
  };

  const getDepartmentList = async (obj) => {
    try {
      let response = await Department.getDepartmentList(obj);
      if (response?.data?.success) {
        let res;
        res = response.data.result || [];
        setDepartment(res);
        setLoading(true);
      } else {
        toastError(response?.data?.message);
        return;
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  const callDelete = async (id) => {
    await DeletePopup(async (canDelete) => {
      if (canDelete) {
        try {
          const response = await Department.delete(id);
          if (response.data.success) {
            toastSuccess(response.data.message);
            getDepartmentList(filterArr);
          } else {
            toastWarn(response.data.message);
          }
        } catch (error) {
          //toastError(error.response.data.message);
          toastError(error);
        }
      }
    });
  };

  const handleSearch = (e) => {
    let value = e.target.value.trim();

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      getDepartmentList({ search: value });
    }, 400);

    setFilterArr((pre) => ({ ...pre, search: value }));
  };

  return (
    <>
      <div>
        <div className="d-md-flex">
          <div>
            {' '}
            <h5 className="text-nowrap">Departments</h5>
          </div>
          <div className="ms-md-auto d-flex ">
            <div className=" me-2">
              {' '}
              <Search
                allowClear
                onChange={handleSearch}
                placeholder="Search "
                value={filterArr.search}
              />
            </div>
            <div>
              <span>
                {buttonPermission?.add && (
                  <Button
                    title="Add"
                    type="primary"
                    htmlType="button"
                    onClick={() => toggleModal('Add', null)}
                    icon={<MdAdd size={20} />}
                  ></Button>
                )}
              </span>
            </div>
          </div>
        </div>

        <div className="mt-2 mb-0">
          <TmFilter
            filterArray={filterList}
            handleFilterChange={handleFilterChange}
          />
        </div>
        <div>
          {loading && (
            <div>
              <TmMasterTable
                buttonPermission={buttonPermission}
                modaldata={modalData}
                rows={department}
                columns={DepartmentCompHeader}
                custom={['checkbox', 'action']}
                toggleModal={toggleModal}
                getDepartmentList={getDepartmentList}
                handleDelete={callDelete}
              />
            </div>
          )}
          {!loading && <Loader />}
        </div>
      </div>
      <span>
        <AntdModalComp
          width="30%"
          toggleModal={() => toggleModal('Close', null)}
          modalOpen={modal}
          modalHeader={modalHeader}
          modalBody={
            <AddDepartment
              modaldata={modalData}
              modalHeader={modalHeader}
              getDepartmentList={getDepartmentList}
              setModal={setModal}
              modal={modal}
            />
          }
        />
      </span>
    </>
  );
};
