import { useEffect, useState } from 'react';
import UserInfo from '../../Components/Header/UserInfo';
import EventHandlers from '../../Components/Header/EventHandlers';
import NotificationHandler from '../../Components/Header/NotificationHandler';
import style from '../../Styles/Header.module.css';
import { Button } from 'antd';
import { TbReload } from 'react-icons/tb';
import { useAuth } from '../../Context/AuthContext';
import {
  toastError,
  toastInfo,
  toastSuccess,
} from '../../Components/Toast/ToastComponent';
import LoginApi from '../../Api/LoginApi';
import { useNavigate } from 'react-router-dom';

function TmHeader() {
  const { authState, login } = useAuth();
  const navigate = useNavigate();
  const ToggleSidebar = () => {
    const [isOpen, setIsopen] = useState(false);

    const ToggleSidebar = () => {
      setIsopen(!isOpen);
    };
  };

  const loginAsOtherUser = async () => {
    let reqData = {
      isAutoLogin: true,
      userRoleID: authState?.switchUser?.userRoleID || null,
      email: authState?.switchUser?.email || '',
    };

    if ([1, 2].includes(authState?.switchUser?.userRoleID)) {
      try {
        let response = await LoginApi.login(reqData);
        if (response?.data?.success && response.data.result) {
          toastSuccess(response.data.message);
          let authAsAdmin = { ...response.data.result };
          await login({ ...authAsAdmin });
          navigate('/');
          window.location.reload();
        } else if (response?.data?.success) {
          toastInfo(response?.data?.message);
        } else {
          toastError(response?.data?.message);
        }
      } catch (error) {
        //toastError(error.response.data.message);
        toastError(error);
      }
    }
  };

  useEffect(() => {}, []);
  return (
    <div className="d-flex justify-content-end align-items-center h-100">
      {/* <div className="px-2">
        <EventHandlers />
      </div> */}
      <div>
        <NotificationHandler />
      </div>
      <div className="px-2">
        <UserInfo />
      </div>

      {Boolean(authState?.switchUser?.switched) &&
        (authState?.id !== 1 || authState?.id !== 2) && (
          <div className="px-2">
            <Button
              type="default"
              htmlType="button"
              title="Return to Existing Login"
              onClick={loginAsOtherUser}
              icon={<TbReload />}
            ></Button>
          </div>
        )}
    </div>
  );
}

export default TmHeader;
