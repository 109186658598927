import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  TimePicker,
  InputNumber,
} from 'antd';
import enUS from 'antd/es/locale/en_US';
import { useForm, Controller } from 'react-hook-form';
import TextArea from 'antd/es/input/TextArea';

import { Label, Row, Col } from 'reactstrap';
import dayjs from 'dayjs';
import {
  DateFormat,
  LEAVETYPE,
  PERMISSION_PAGE_ROUTES,
} from '../../constant/constant';
import Mandatory from '../ReusableComponents/Mandatory';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import { LeaveManageApi } from '../../Api/LeaveManageApi';
import {
  toastError,
  toastInfo,
  toastSuccess,
  toastWarn,
} from '../Toast/ToastComponent';
import { useAuth } from '../../Context/AuthContext';
import { useParams } from 'react-router-dom';

const HistoryModal = ({
  toggleModal,
  modal,
  setHistoryData,
  historyData,
  setModal,
  orginalValues,
  handleHistoryData,
  isModule,
}) => {
  const [form] = Form.useForm();

  const { authState } = useAuth();

  const { edit_id, view_type } = useParams();

  const { Option } = Select;

  const onSubmit = async (data) => {
    let obj = {
      reason: data?.reason,
      project_id: isModule ? orginalValues?.project_id : atob(edit_id),
      ...(isModule && { module_id: orginalValues?.id }),
      // date: dayjs().format('DD/MM/YYYY'),
      // month: dayjs().format('MMMM'),
      // year: dayjs().year(),
      fieldType: modal?.key,
      orginalValue: orginalValues[modal?.key],
    };

    handleHistoryData(modal?.key, obj);

    setModal({ modalLabel: undefined, isOpen: false, key: undefined });
  };

  const customizeRequiredMark = (label, { required }) => (
    <>
      {label}
      {required && <Mandatory />}
    </>
  );

  return (
    <>
      <div>
        <Form
          layout="vertical"
          form={form}
          onFinish={onSubmit}
          requiredMark={customizeRequiredMark}
        >
          <Row>
            <Col>
              <Form.Item
                name="reason"
                label={`To modify the ${modal?.modalLabel}, please add a reason to maintain the history.`}
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Maximum 200 characters"
                  maxLength={200}
                />
              </Form.Item>
              <div className="text-center antdBtn">
                <Button
                  onClick={() => toggleModal()}
                  type="default"
                  htmlType="button"
                >
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default HistoryModal;
