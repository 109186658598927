import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import style from '../../Styles/Header.module.css';
import { toastError, toastInfo } from '../Toast/ToastComponent';
import NotificationAPI from '../../Api/notificationsAPI.js';
import { useNavigate } from 'react-router-dom';

// const notificationArr = [
//   {
//     date_of_notification: '17/09/2024',
//     notification_data: [
//       {
//         title:
//           "Oops! It looks like you haven't submitted your timesheet for today yet.",
//         dateTime: 'Apr 17, 01:10 PM',
//         isRead: false,
//       },
//       {
//         title:
//           'This concise notification gently reminds users of the pending task and offers a direct action button to encourage timely completion.',
//         dateTime: 'Apr 17, 01:10 PM',
//         isRead: true,
//       },
//       {
//         title: 'Your timesheet of the day 15/04/2024 is rejected.',
//         dateTime: 'Apr 17, 01:10 PM',
//         isRead: false,
//       },
//     ],
//   },
//   {
//     date_of_notification: '17/09/2024',
//     notification_data: [
//       {
//         title:
//           "Oops! It looks like you haven't submitted your timesheet for today yet.",
//         dateTime: 'Apr 17, 01:10 PM',
//         isRead: true,
//       },
//       {
//         title:
//           'This concise notification gently reminds users of the pending task and offers a direct action button to encourage timely completion.',
//         dateTime: 'Apr 17, 01:10 PM',
//         isRead: false,
//       },
//       {
//         title: 'Your timesheet of the day 15/04/2024 is rejected.',
//         dateTime: 'Apr 17, 01:10 PM',
//         isRead: false,
//       },
//     ],
//   },
// ];

const usersLeaveNotification = ['LEAVE_UPDATE', 'LEAVE_DELETE', 'LEAVE_APPLY'];

const myTimesheetNotification = ['TIMESHEET', 'LOCK_REMAINDER', 'LOCKED'];

const myLeaveNotification = ['LEAVE_REQUEST'];

const NotificationBody = ({
  notificationArr,
  authState,
  getNotifications,
  toggleDrawer,
}) => {
  const navigate = useNavigate();

  const markAsRead = async () => {
    try {
      const response = await NotificationAPI.edit({ id: authState?.id });
      if (response?.data?.success) {
        getNotifications();
      } else {
        toastError(response?.data?.message);
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  const handleRoute = async (notificationData, index, id) => {
    try {
      if (usersLeaveNotification.includes(notificationData.type)) {
        navigate('/user_requests');
      } else if (myTimesheetNotification.includes(notificationData.type)) {
        navigate('/timesheet');
      } else if (myLeaveNotification.includes(notificationData.type)) {
        navigate('/my_requests');
      }

      if (notificationData?.isRead == false) {
        let obj = {
          id,
          index,
        };
        const response = await NotificationAPI.read(obj);
        if (response?.data?.success) {
          getNotifications();
        } else {
          toastInfo(response?.data?.message);
        }
      }
      toggleDrawer();
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  return (
    <div className="h-100">
      <div
        className={` ${style.markRead} d-flex justify-content-end align-items-center header_bgcolor px-2`}
      >
        <div>
          <button
            onClick={() => markAsRead()}
            className="btn p-0 m-0 supported_text"
          >
            Mark all as read
          </button>
        </div>
      </div>
      <div className={`${style.notifyBdy} p-3 pt-0`}>
        {notificationArr?.length > 0 &&
          notificationArr?.map((notify, indx) => (
            <div className="mt-3" key={indx + 98}>
              <div className="text-secondary">
                {notify.date_of_notification}
              </div>
              {notify?.notification_data?.map((item, idx) => (
                <div
                  onClick={() => handleRoute(item, idx, notify.id)}
                  className={`${
                    item.isRead
                      ? style.notificationBadgeRead
                      : style.notificationBadge
                  } mt-2 border rounded-1 p-1 px-2 tmPointer`}
                  key={idx + 55}
                >
                  <p className="mb-1">{item.title}</p>
                  <div className="text-end">
                    <small className="text-secondary">{item.dateTime}</small>
                  </div>
                </div>
              ))}
            </div>
          ))}
        {notificationArr?.length <= 0 && (
          <section className="mt-3">
            <div className="text-secondary fw-semibold">No notifications</div>
          </section>
        )}
      </div>
    </div>
  );
};

export default NotificationBody;
