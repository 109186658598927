import React, { useEffect, useRef, useState } from 'react';
import NotificationTable from './NotificationTable';
import { Button, Input } from 'antd';
import { MdAdd } from 'react-icons/md';
import { toastError, toastInfo } from '../../Toast/ToastComponent';
import EmailApi from '../../../Api/EmailApi';
import Loader from '../../ReusableComponents/Loader';
import {
  EMAIL_NOTIFICATIONS,
  MODAL_HEIGHT,
  MONTHS,
  notificationStatus,
} from '../../../constant/constant';
import TmFilter from '../../ReusableComponents/TmFilter';
import { useSelector } from 'react-redux';
import { getNotificationHistory } from '../../../store/reducers/actionPermission';
import { useNavigate } from 'react-router-dom';
import AntdModalComp from '../../ReusableComponents/AntdModalComp';
import ViewEmailHistory from './ViewEmailHistory';
import {
  generateCurrentMonth,
  generateCurrentYear,
  generateYears,
} from '../../../ReusableFn/reusableFn';
import useFilterStateData from '../../../customHooks/useFilterStateData';
const { Search } = Input;

let viewData = '';
function EmailHistory() {
  const data = useSelector(getNotificationHistory);
  const { month: filterStateMonth, year: filterStateYear } =
    useFilterStateData();
  const [filterValue, setfilterValue] = useState({
    status: '',
    search: '',
    month: filterStateMonth,
    year: filterStateYear,
  });
  // let filterValue = {
  //   status: '',
  //   search: '',
  //   month: filterStateMonth,
  //   year: filterStateYear,
  // };
  const navigate = useNavigate();

  const debounceTimeoutRef = useRef(null);
  const [emailHistory, setEmailHistory] = useState([]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const filterArray = [
    {
      options: MONTHS,
      optionFilterProp: 'label',
      placeholder: 'Month',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: filterStateMonth,
      controlName: 'month',
      allowClear: false,
      input: 'select',
    },
    {
      options: generateYears() || [],
      optionFilterProp: 'label',
      placeholder: 'Year',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: filterStateYear,
      controlName: 'year',
      allowClear: false,
      input: 'select',
    },
    {
      options: notificationStatus,
      optionFilterProp: 'label',
      placeholder: 'Status',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: null,
      controlName: 'status',
      allowClear: true,
      input: 'select',
    },
    {
      options: EMAIL_NOTIFICATIONS,
      optionFilterProp: 'label',
      placeholder: 'Event Type',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: null,
      controlName: 'type',
      allowClear: true,
      input: 'select',
    },
    {
      order: 1,
      col: 'auto',
      className: 'ms-auto pe-0',
      input: 'button',
      type: 'dashed',
      htmlType: 'button',
      text: 'Reset',
    },
  ];

  const getEmailHistory = async (obj) => {
    try {
      const response = await EmailApi.getEmailHistory({
        ...(obj ? obj : filterValue),
      });
      if (response?.data?.success) {
        setEmailHistory(response?.data?.result);
        setLoading(true);
      } else {
        toastInfo(response?.data?.message);
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  const handleButtonPermission = () => {
    if (!data?.checked) {
      navigate('/');
    }
  };

  const handleFilterChange = (obj) => {
    // filterValue = { ...filterValue, [keys]: e };
    // filterValue = obj || {};
    setfilterValue((pre) => ({ ...pre, ...obj }));
    // filterValue = { ...filterValue, ...obj } || {};
    getEmailHistory({ ...filterValue, ...obj });
  };

  const handleSearch = (e) => {
    let value = e.target.value.trim();

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      handleFilterChange({ ...filterValue, search: value });
    }, 400);
  };

  useEffect(() => {
    getEmailHistory();

    if (data) {
      handleButtonPermission();
    }

    return () => {
      viewData = '';
    };
  }, [data]);

  const handleClick = (item, type) => {
    viewData = item;
    setModal(!modal);
  };
  const toggleModal = () => {
    setModal(!modal);
  };

  return (
    <>
      {loading && (
        <div>
          <div className="row mx-0 mb-2 ">
            <div className="col-md-4 px-0">
              <h5 className="text-nowrap">Notification History</h5>
            </div>
            <div
              className="col-md-3 px-0 ps-md-5"
              style={{ marginLeft: 'auto' }}
            >
              <Search
                allowClear
                onChange={handleSearch}
                placeholder="Search "
              />
            </div>
          </div>

          <div className=" p-0">
            <TmFilter
              filterArray={filterArray}
              handleFilterChange={handleFilterChange}
            />
          </div>
          <NotificationTable rows={emailHistory} handleClick={handleClick} />
        </div>
      )}
      {!loading && <Loader />}
      <span>
        <AntdModalComp
          width="60%"
          toggleModal={toggleModal}
          modalOpen={modal}
          modalHeader={<>View Notification History</>}
          height={viewData?.content ? MODAL_HEIGHT.md : MODAL_HEIGHT.auto}
          modalBody={<ViewEmailHistory viewData={viewData} />}
        />
      </span>
    </>
  );
}
export default EmailHistory;
