import React, { useEffect, useState } from 'react';
import style from '../../Styles/UserManagementView.module.css';
import { useNavigate, useParams } from 'react-router';
import { FaArrowLeft, FaRegEye } from 'react-icons/fa';
import { CardBody, Card, Row, Col } from 'reactstrap';
import { Button, Checkbox, Image } from 'antd';
import {
  toastError,
  toastInfo,
  toastSuccess,
  toastWarn,
} from '../Toast/ToastComponent';
import UserApi from '../../Api/UserApi';
import xlsx from '../../Assets/xlsx_icon.svg';
import pdf from '../../Assets/Adobe PDF.svg';
import pdfViewer from '../../ReusableFn/pdfViewer';
import dayjs from 'dayjs';
import { dayjsToDateFormat } from '../../ReusableFn/reusableFn';
import { IoMailOutline } from 'react-icons/io5';
import { IoIosMail } from 'react-icons/io';
import AntdModalComp from '../ReusableComponents/AntdModalComp';
import { FiEdit } from 'react-icons/fi';

let imageUrldata = '';
const UserManagementView = () => {
  const [viewData, setViewdata] = useState({});
  const [modal, setModal] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const { edit_id, permission } = useParams();

  const navigate = useNavigate();

  const SendCredent = () => {
    return (
      <>
        <div>Are you sure you want to send credentials via mail?</div>
        <div className="mt-4 antdBtn text-center">
          <Button
            type="default"
            onClick={() => setModal(!modal)}
            htmlType="button"
          >
            Cancel
          </Button>
          <Button type="primary" onClick={() => handleCheck()}>
            Send
          </Button>
        </div>
      </>
    );
  };

  const toggleModal = () => {
    setModal(!modal);
  };
  useEffect(() => {
    getViewData();
  }, []);

  const previewDoc = (item) => {
    if (item.media_type === 'image') {
      imageUrldata = item.url;
      setPreviewOpen(true);
      return;
    } else if (item.media_type === 'pdf') {
      pdfViewer(item?.url);
      imageUrldata = '';
    }
  };

  const getViewData = async () => {
    try {
      const response = await UserApi.view(atob(edit_id));
      if (response.data.success) {
        setViewdata(response.data.result);
      } else {
        toastWarn(response.data.message);
      }
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };

  const handleCheck = async () => {
    try {
      const response = await UserApi.sendCredentials({ user_id: viewData?.id });
      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
      } else {
        toastInfo(response?.data?.message);
      }
      toggleModal();
    } catch (error) {
      //toastError(error.response.data.message);
      toastError(error);
    }
  };
  const handleEdit = () => {
    navigate(`/add_user/${atob(edit_id)}`);
  };
  return (
    <div>
      <div className="row justify-content-between px-0 mx-0">
        <div className={`col-auto text-nowrap px-0 ${style['add-user']}`}>
          <span
            className={`${style['arrow-icon']} border px-2 pb-1`}
            onClick={() => navigate('/user')}
          >
            <FaArrowLeft />
          </span>
          &nbsp; View User
        </div>

        {atob(permission) == 'true' &&
          viewData.id !== 1 &&
          viewData.id !== 2 && (
            <div
              className={`px-0 col-md-9 row align-items-center justify-content-end mx-0`}
            >
              <div className={`col-auto px-0 ms-2 pt-2 pt-md-0`}>
                <Button
                  type="default"
                  size="medium"
                  htmlType="button"
                  onClick={handleEdit}
                >
                  <div className="d-flex">
                    <div>
                      <FiEdit className="p-0 m-0 " size={16} />
                    </div>
                    <div style={{ paddingTop: '2px' }} className="ms-1  ">
                      Edit
                    </div>
                  </div>
                </Button>
              </div>
              <div className="col-auto px-0 ms-2 pt-2 pt-md-0">
                {' '}
                <Button type="primary" htmlType="button" onClick={toggleModal}>
                  <div className="d-flex">
                    <div>
                      <IoIosMail className="p-0 m-0 " size={16} />
                    </div>
                    <div style={{ paddingTop: '2px' }} className="ms-1  ">
                      Send Credentials
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          )}
      </div>
      <Row className="mt-2 mx-0">
        <Col className="px-0">
          <Card>
            <CardBody>
              <Row className="">
                <div className={style['title-style']}>Basic Information</div>
                <Col className="" md={4}>
                  <div className={style['Header-Bold']}>
                    Employee First Name
                  </div>

                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.first_name}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={style['Header-Bold']}>Employee Last Name</div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.last_name}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={style['Header-Bold']}>Gender</div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.gender ?? '-'}
                  </div>
                </Col>
                <Col md={4}>
                  <div className={style['Header-Bold']}> Date of Birth</div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.date_of_birth ? viewData?.date_of_birth : '-'}
                  </div>
                </Col>
                <Col md={4}>
                  <div className={style['Header-Bold']}>Year of Experience</div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.year_of_experience ?? '-'}
                  </div>
                </Col>
                <Col md={4}>
                  <div className={style['Header-Bold']}> User Role</div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.userRoleID ?? '-'}
                  </div>
                </Col>
                <Col md={4}>
                  <div className={style['Header-Bold']}>Email</div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.email ?? '-'}
                  </div>
                </Col>
                <Col md={4}>
                  <div className={style['Header-Bold']}>Password</div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {'****'}
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <div className={style['title-style']}>Contact Information</div>
                <Col className="" md={4}>
                  <div className={style['Header-Bold']}>Personal Email</div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.personal_email ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={style['Header-Bold']}> Phone Number</div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.phone ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={style['Header-Bold']}>Permanent Address</div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.permanent_address ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={` mt-2 ${style['Header-Bold']}`}>
                    Residential Address
                  </div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.residential_address ?? '-'}
                  </div>
                </Col>
              </Row>

              {viewData['additional_contacts']?.length > 0 && (
                <Row className="mt-4">
                  <section className={`${style['title-style']} my-2`}>
                    Additional Contact Information
                  </section>
                  <div>
                    {viewData['additional_contacts'].map((item, idx) => (
                      <div className="">
                        <div className={`${style['title-style']} mt-4`}>
                          Contact Information {Number(idx) + 1}
                        </div>

                        <Row className="mt-2">
                          <Col className="" md={4}>
                            <div className={style['Header-Bold']}>
                              First Name
                            </div>
                            <div
                              className="mt-1"
                              style={{ fontWeight: 'bold' }}
                            >
                              {item.add_first_name ?? '-'}
                            </div>
                          </Col>
                          <Col className="" md={4}>
                            <div className={style['Header-Bold']}>
                              Last Name
                            </div>
                            <div
                              className="mt-1"
                              style={{ fontWeight: 'bold' }}
                            >
                              {item.add_last_name ?? '-'}
                            </div>
                          </Col>
                          <Col className="" md={4}>
                            <div className={style['Header-Bold']}>Gender</div>
                            <div
                              className="mt-1"
                              style={{ fontWeight: 'bold' }}
                            >
                              {item.gender ?? '-'}
                            </div>
                          </Col>
                          <Col className="" md={4}>
                            <div className={style['Header-Bold']}>Email</div>
                            <div
                              className="mt-1"
                              style={{ fontWeight: 'bold' }}
                            >
                              {item.add_email ?? '-'}
                            </div>
                          </Col>
                          <Col className="" md={4}>
                            <div className={style['Header-Bold']}>
                              Phone Number
                            </div>
                            <div
                              className="mt-1"
                              style={{ fontWeight: 'bold' }}
                            >
                              {item.add_phone ?? '-'}
                            </div>
                          </Col>
                          <Col className="" md={4}>
                            <div className={style['Header-Bold']}>
                              Permanent Address
                            </div>
                            <div
                              className="mt-1"
                              style={{ fontWeight: 'bold' }}
                            >
                              {item.add_permanent_address ?? '-'}
                            </div>
                          </Col>
                          <Col className="" md={4}>
                            <div className={style['Header-Bold']}>Relation</div>
                            <div
                              className="mt-1"
                              style={{ fontWeight: 'bold' }}
                            >
                              {item.relation ?? '-'}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                </Row>
              )}

              <Row className="mt-4">
                <div className={style['title-style']}>Job Information</div>
                <Col className="" md={4}>
                  <div className={style['Header-Bold']}>Date of Joining</div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.date_of_joining
                      ? viewData?.date_of_joining
                      : '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={style['Header-Bold']}>Employee Code</div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.employeeCode ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={style['Header-Bold']}>Department</div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.departmentID ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={` mt-2 ${style['Header-Bold']}`}>
                    Designation
                  </div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.designationID ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={` mt-2 ${style['Header-Bold']}`}>
                    Reporting Authority
                  </div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.reportAuthorityID ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={` mt-2 ${style['Header-Bold']}`}>
                    Employment Type
                  </div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.employmentType ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={` mt-2 ${style['Header-Bold']}`}>
                    Employment Mode
                  </div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.employmentMode ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={` mt-2 ${style['Header-Bold']}`}>
                    Probation Period
                  </div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.probation_period ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={` mt-2 ${style['Header-Bold']}`}>
                    Notice Period
                  </div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.notice_period ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={` mt-2 ${style['Header-Bold']}`}>
                    Service Confirmation Status
                  </div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.serviceConfirmation ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={` mt-2 ${style['Header-Bold']}`}>
                    Date of Service Confirmation
                  </div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.date_of_serviceConfirmation ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={` mt-2 ${style['Header-Bold']}`}>
                    Contract or Training Period
                  </div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.contract_training_period ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={` mt-2 ${style['Header-Bold']}`}>
                    Background Verification Status
                  </div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.background_verification_status ?? '-'}
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <div className={style['title-style']}>
                  Enrollment Information
                </div>
                <Col className="" md={4}>
                  <div className={style['Header-Bold']}> Annual CTC </div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.annual_CTC ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={style['Header-Bold']}> Monthly CTC </div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.monthly_CTC ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={style['Header-Bold']}>Monthly Take Home </div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.monthly_take_home ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={` mt-2 ${style['Header-Bold']}`}>
                    PF Amount{' '}
                  </div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.PF_Amt ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={` mt-2 ${style['Header-Bold']}`}>
                    ESI Amount{' '}
                  </div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.ESI_Amt ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={` mt-2 ${style['Header-Bold']}`}>
                    Variable{' '}
                  </div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.variable ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={` mt-2 ${style['Header-Bold']}`}>
                    Incentive{' '}
                  </div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.incentive ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={` mt-2 ${style['Header-Bold']}`}>
                    Increment{' '}
                  </div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.increment_amount ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={` mt-2 ${style['Header-Bold']}`}>
                    TDS (Annual TDS Deduction)
                  </div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.TDS ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={` mt-2 ${style['Header-Bold']}`}>
                    Last Appraisal Date
                  </div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.last_appraisal_date ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={` mt-2 ${style['Header-Bold']}`}>UAN</div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.UAN_number ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={` mt-2 ${style['Header-Bold']}`}>
                    ESIC Number
                  </div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.ESIC_number ?? '-'}
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <div className={style['title-style']}>
                  Employment Information
                </div>
                <Col className="" md={4}>
                  <div className={style['Header-Bold']}>Employment Status</div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.employmentStatus ?? '-'}
                  </div>
                </Col>

                {viewData?.employmentStatus === 'WORKING' && (
                  <Col className="" md={4}>
                    <div className={style['Header-Bold']}>Probation</div>
                    <div className="mt-1" style={{ fontWeight: 'bold' }}>
                      {viewData?.probation ?? '-'}
                    </div>
                  </Col>
                )}

                {viewData?.employmentStatus === 'NOT WORKING' && (
                  <>
                    <Col className="" md={4}>
                      <div className={style['Header-Bold']}>
                        Employment Exit Status
                      </div>
                      <div className="mt-1" style={{ fontWeight: 'bold' }}>
                        {viewData?.exit_status ?? '-'}
                      </div>
                    </Col>
                    <Col className="" md={4}>
                      <div className={style['Header-Bold']}>
                        Certificate Issued
                      </div>
                      <div className="mt-1" style={{ fontWeight: 'bold' }}>
                        {viewData?.certificated_issue ?? '-'}
                      </div>
                    </Col>
                    <Col className="" md={4}>
                      <div className={` mt-2 ${style['Header-Bold']}`}>
                        Date of Exit
                      </div>
                      <div className="mt-1" style={{ fontWeight: 'bold' }}>
                        {viewData?.date_of_exit ?? '-'}
                      </div>
                    </Col>
                    <Col className="" md={4}>
                      <div className={` mt-2 ${style['Header-Bold']}`}>
                        Settlement Graduation
                      </div>
                      <div className="mt-1" style={{ fontWeight: 'bold' }}>
                        {viewData?.settlement_eligibility ?? '-'}
                      </div>
                    </Col>
                    <Col className="" md={4}>
                      <div className={` mt-2 ${style['Header-Bold']}`}>
                        Payment Status
                      </div>
                      <div className="mt-1" style={{ fontWeight: 'bold' }}>
                        {viewData?.settlement_graduation ?? '-'}
                      </div>
                    </Col>
                    {viewData?.settlement_graduation === 'PAID' && (
                      <Col className="" md={4}>
                        <div className={` mt-2 ${style['Header-Bold']}`}>
                          Amount
                        </div>
                        <div className="mt-1" style={{ fontWeight: 'bold' }}>
                          {viewData?.Amount ?? '-'}
                        </div>
                      </Col>
                    )}
                    {/* {viewData?.settlement_graduation !== 'PAID' && (
                      <Col className="" md={4}>
                        <div className={` mt-2 ${style['Header-Bold']}`}>
                          &nbsp;
                        </div>
                        <div className="mt-1" style={{ fontWeight: 'bold' }}>
                          ( Need To Pay Graduate to Employee)
                        </div>
                      </Col>
                    )} */}
                  </>
                )}
              </Row>
              <Row className="mt-4"></Row>

              <Row className="mt-4">
                <div className={style['title-style']}>
                  Timesheet Information
                </div>
                <Row>
                  <Col>
                    <div>
                      <div className={style['Header-Bold']}>
                        No. of CL Given
                      </div>
                      <div className="mt-1" style={{ fontWeight: 'bold' }}>
                        {viewData?.cl_given ?? '-'}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <div className={style['Header-Bold']}>
                        No. of SL Given
                      </div>
                      <div className="mt-1" style={{ fontWeight: 'bold' }}>
                        {viewData?.sl_given ?? '-'}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <div className={style['Header-Bold']}>
                        Paternity Leave
                      </div>
                      <div className="mt-1" style={{ fontWeight: 'bold' }}>
                        {viewData?.paternity_leave ?? '-'}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <div className={style['Header-Bold']}>
                        Maternity Leave
                      </div>
                      <div className="mt-1" style={{ fontWeight: 'bold' }}>
                        {viewData?.maternity_leave ?? '-'}
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <div className={style['Header-Bold']}>Marriage Leave</div>
                      <div className="mt-1" style={{ fontWeight: 'bold' }}>
                        {viewData?.marriage_leave ?? '-'}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Row>

              <Row className="mt-4">
                <Col className="" md={4}>
                  <div className={style['Header-Bold']}> 1st approvers</div>
                  <div className="mt-1 ">
                    <b> {viewData?.first_approvers ?? '-'}</b>{' '}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={style['Header-Bold']}>2nd Approvers</div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.second_approvers ?? '-'}
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <div className={style['title-style']}>Other Information</div>
                <Col className="" md={4}>
                  <div className={style['Header-Bold']}> Aadhar Number</div>
                  <div className="mt-1 " style={{ fontWeight: 'bold' }}>
                    {viewData?.aadhar_number ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={style['Header-Bold']}> Pan Number</div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.pan_number ?? '-'}
                  </div>
                </Col>
                <Col className="" md={4}>
                  <div className={style['Header-Bold']}> Blood Group</div>
                  <div className="mt-1" style={{ fontWeight: 'bold' }}>
                    {viewData?.bloodGroup ?? '-'}
                  </div>
                </Col>
              </Row>

              <Row className="mt-4">
                {viewData?.fileInfo?.length > 0 && (
                  <>
                    <div className={style['title-style']}>Document</div>
                    {viewData?.fileInfo.map((item, indx) => (
                      <Row key={indx}>
                        <Col className="" md={4}>
                          <div
                            key={indx}
                            className="border rounded-1 p-2 mt-2 d-flex justify-content-between align-items-center"
                          >
                            <div className="border">
                              <img
                                height={50}
                                width={50}
                                src={
                                  item?.media_type == 'pdf'
                                    ? pdf
                                    : item?.media_type == 'sheet'
                                    ? xlsx
                                    : item?.url
                                }
                                alt=""
                                className="rounded-1"
                              />
                            </div>
                            <div
                              className="ms-2 tmTextOverflow fw-semibold"
                              title={item?.name}
                            >
                              {item?.name}
                            </div>

                            <div>
                              <button
                                className="btn p-0 m-0 icon-hover ms-1"
                                onClick={() => previewDoc(item)}
                                type="button"
                              >
                                <FaRegEye size={16} />
                              </button>
                            </div>
                          </div>
                        </Col>
                        {indx === 0 && (
                          <>
                            <Col md={4}>
                              <div className={style['Header-Bold']}>
                                Document Name
                              </div>
                              <div
                                className="mt-1"
                                style={{ fontWeight: 'bold' }}
                              >
                                {viewData?.document_name ?? '-'}
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className={style['Header-Bold']}>
                                Created At
                              </div>
                              <div
                                className="mt-1"
                                style={{ fontWeight: 'bold' }}
                              >
                                {viewData?.created_date
                                  ? viewData?.created_date
                                  : '-'}
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>
                    ))}
                  </>
                )}
                {viewData?.fileInfo?.length > 0 && (
                  <>
                    {viewData?.fileInfo[0].media_type == 'image' && (
                      <Image
                        wrapperStyle={{
                          display: 'none',
                        }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                        }}
                        src={imageUrldata}
                      />
                    )}
                  </>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <AntdModalComp
        width="30%"
        toggleModal={toggleModal}
        modalOpen={modal}
        modalHeader={<>Send Credentials</>}
        modalBody={<SendCredent setModal={setModal} />}
      />
    </div>
  );
};

export default UserManagementView;
